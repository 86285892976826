import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import PropTypes from "prop-types";
import { Row, Col, Button } from "react-bootstrap";
import classNames from "classnames";
import moment from "moment";

import Card from "../Card";
import HomebuyerModal from "../HomebuyerModal";
import styles from "./HomebuyerCard.module.scss";

export default function HomebuyerCard({
  className,
  professionalDetails,
  professionalsCategories,
  hired,
  handleHire,
  isHiring,
  ...other
}) {
  const {
    PhotoLink,
    FirstName,
    LastName,
    CompanyName,
    Address,
    Email,
    Phone,
    WorkingSince,
    NoPhysicalAddress,
    DisplayContact,
    Id,
    CategoryName,
    Contacted,
    Reviews
  } = professionalDetails;

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    handleHire(Id);
  };

  const fallBackImg =
    "https://netstock-europe.com/wp-content/uploads/2016/09/fg-avatar-anonymous-user-retina-300x300.png";

  const averageRating = () => {

    if (!Reviews || Reviews.length === 0) {
      return "No reviews yet"
    }

    let totalStars = 0

    for (let review of Reviews) {
      totalStars += review.stars
    }

    const value = Math.round(totalStars / Reviews.length) / 2

    return <div className="d-flex">
      <ReactStars
        count={5}
        value={value}
        isHalf
        size={20}
        edit={false}
        activeColor="#F4BE52"
        color="#CDCDCD"
      />
      <span className="ml-1">({Reviews.length})</span>
    </div>
  }

  return (
    <>
      <Card
        className={classNames(styles.root, className)}
        {...other}
        onClick={toggleOpen}
      >
        {Contacted ? (
          <p
            className={classNames(
              "mt-2 position-absolute d-flex align-items-center justify-content-center",
              styles.hired
            )}
          >
            {CategoryName != "Real Estate Agents"
              ? "Getting Quote"
              : "Connected"}

            <img className="icon icon-rounded-check mr-1 ml-2" alt="" />
          </p>
        ) : (
            <Button
              variant="primary"
              className={classNames(
                "position-absolute d-flex align-items-center justify-content-center",
                styles.moreButton
              )}
              onClick={handleButtonClick}
            >
              {isHiring ? (
                <img
                  src="/images/loading.gif"
                  className="loader"
                  alt="Processing..."
                />
              ) : CategoryName !== "Real Estate Agents" ? (
                "Get Quote"
              ) : (
                    "Connect"
                  )}
            </Button>
          )}

        <Row>
          <Col md="auto text-center mb-4 mb-md-0">
            <img
              src={professionalDetails.PhotoLink || fallBackImg}
              alt=""
              className={styles.profilePic}
            />
          </Col>
          <Col
            md="auto"
            xs={12}
            className={classNames(
              "flex-md-grow-1 flex-md-shrink-1 text-center text-md-left",
              styles.content
            )}
          >
            <h6 className="mb-0">
              {FirstName && LastName && DisplayContact
                ? `${FirstName} ${LastName}`
                : CompanyName}
            </h6>
            <p className={classNames("mb-2", styles.subheader)}>
              {FirstName && LastName && DisplayContact ? CompanyName : " "}
            </p>
            <div className={classNames("d-flex align-items-center mb-4 justify-content-center justify-content-md-start", styles.experience)}>
              {moment(WorkingSince)._isValid && (
                <p className="mb-0">
                  <span>Experience: </span>
                  {moment(WorkingSince).fromNow().slice(0, -3)}
                </p>
              )}
            </div>
            <div className={classNames("d-flex align-items-center mb-2 justify-content-center justify-content-md-start", styles.address)}>
              {
                !NoPhysicalAddress &&
                <>
                  <i className="icon icon-pin mr-2" />
                  <p className="mb-0">{Address || "[Address]"}</p>
                </>
              }
            </div>
            <div className="d-flex align-items-center mb-0 justify-content-center justify-content-md-start">
              <p className="mb-0">
                {averageRating()}
              </p>
            </div>
            {/*<div className="d-flex align-items-center mb-2 justify-content-center justify-content-md-start">
            <i className="icon icon-pin mr-2" />
            <p className="mb-0">{Address}</p>
          </div>
          <div className="d-flex align-items-center justify-content-center justify-content-md-start">
            <i className="icon icon-mail mr-2" />
            <p className="mb-0">{Email}</p>
            <i className="icon icon-phone mr-2 ml-4" />
            <p className="mb-0">{Phone}</p>
          </div>*/}
          </Col>
        </Row>
      </Card>

      <HomebuyerModal
        show={open}
        handleClose={toggleOpen}
        professionalDetails={professionalDetails}
        categories={professionalsCategories}
      />
    </>
  );
}

HomebuyerCard.propTypes = {
  className: PropTypes.string,
  professionalDetails: PropTypes.object,
  professionalsCategories: PropTypes.array,
};
