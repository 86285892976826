import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import DetailsTabs from "../DetailsTabs";
import _ from "underscore";
import "./index.scss";
import Loader from "../../../utils/Loader";
import { getIconName } from "../../../utils";
import NotReady from "../../Dashboard/Timeline/NotReady";

const Details = ({
  details,
  selectedStep,
  professionals,
  tips,
  isTimelineStepLoading,
  handleHire,
  hiringProfessionalId,
  isTimelineProfessionalsLoading,
  markStepCompleted,
  isMarkingStepCompleted,
  handleSaveDetailFields,
  isSavingDetailFields,
  handleSubmitExternalProfessional,
  goBack,
  stepInfo,
  isGuest,
}) => {
  if (_.isEmpty(selectedStep)) {
    return null;
  }

  return (
    <div className={"rui__timeline-details"}>
      <Row>
        <Col xs={7}>
          <h3>
            <img className={`icon icon-caret`} onClick={goBack} />
            {selectedStep.StepName}
          </h3>
          <p className={"sublabel"}>
            <small>
              Estimated: {selectedStep.DurationDays} day
              {selectedStep.DurationDays > 1 ? "s" : ""}
            </small>
          </p>
        </Col>
        {!isGuest && (
          <Col xs={5} className={"centered step-complete-btn-container"}>
            {selectedStep.Completed ? (
              <p className={"completed"}>
                {isMarkingStepCompleted ? (
                  <img
                    src="/images/loading.gif"
                    className={"loader"}
                    alt="Processing..."
                  />
                ) : (
                  <React.Fragment>
                    <span>
                      <img className={"icon icon-rounded-check"} alt="" />
                      Completed
                    </span>
                    <span
                      className={"on-hover"}
                      onClick={() => markStepCompleted(false)}
                    >
                      Mark Incomplete
                    </span>
                  </React.Fragment>
                )}
              </p>
            ) : (
              <React.Fragment>
                <Button
                  className={"step-complete-btn--mobile"}
                  onClick={() => markStepCompleted(true)}
                >
                  {isMarkingStepCompleted ? (
                    <img
                      src="/images/loading.gif"
                      className={"loader"}
                      alt="Processing..."
                    />
                  ) : (
                    <img className={"icon icon-check"} alt="" />
                  )}
                </Button>
                <Button
                  className={"step-complete-btn--desktop "}
                  onClick={() => markStepCompleted(true)}
                >
                  {isMarkingStepCompleted ? (
                    <img
                      src="/images/loading.gif"
                      className={"loader"}
                      alt="Processing..."
                    />
                  ) : (
                    "Mark as completed"
                  )}
                </Button>
              </React.Fragment>
            )}
          </Col>
        )}
      </Row>
      {!isGuest ? (
        <DetailsTabs
          handleHire={handleHire}
          hiringProfessionalId={hiringProfessionalId}
          stepInfo={stepInfo}
          details={details}
          professionals={professionals}
          handleSave={handleSaveDetailFields}
          handleSubmitExternalProfessional={handleSubmitExternalProfessional}
          isSavingDetailFields={isSavingDetailFields}
          isTimelineStepLoading={isTimelineStepLoading}
          isTimelineProfessionalsLoading={isTimelineProfessionalsLoading}
          tips={tips}
        />
      ) : (
        <NotReady />
      )}
    </div>
  );
};

export default Details;
