import React from "react";
import PropTypes from 'prop-types'
import {Col} from "react-bootstrap";
import classNames from "classnames"

import styles from './Card.module.scss'

export default function Card({children, className, ...other}) {

  return <Col className="mb-4" {...other}>
    <div className={classNames(styles.root,className)}>
      {children}
    </div>
  </Col>

}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
