import React from "react";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames";
import "./index.scss";
import queryParams from "queryparams";

const Header = ({
  minimal,
  shouldShowTimeline,
  shouldShowSidebar,
  toggleSidebar,
  toggleTimeline,
  isTimelineOpen = false,
  logo,
  classes = "",
  isSidebarOpen = false,
  relative,
}) => {
  return (
    <div
      id={"rui__header"}
      className={classNames(classes, { relative: relative })}
    >
      {shouldShowSidebar && minimal && (
        <a
          className={classNames("hamburger", { "is-open": isSidebarOpen })}
          onClick={toggleSidebar}
        >
          {" "}
          <img className={"icon icon-hamburger"}></img>{" "}
        </a>
      )}

      <Col
        className={classNames("d-md-none d-lg-none d-xl-none centered", {
          "always-show": logo,
        })}
      >
        <div className="d-flex align-items-center justify-content-center">
          <img
            className={"logo"}
            src="/images/Hyponia-Logo-dark.png"
            alt="Hyponia logo"
          />
        <p className={classNames("red mb-0 ml-1 beta")}>BETA</p>
        </div>

        {(!minimal || queryParams()["onboarding"] === "complete") && (
          <p className={"logo-subline"}>Make home buying simple</p>
        )}
      </Col>

      {/* {
                    shouldShowTimeline && minimal && <a className={classNames("timeline", {"is-open": isTimelineOpen})} onClick={toggleTimeline}> <img className={"icon icon-timeline-hamburger"}></img> </a>
                } */}
    </div>
  );
};

export default Header;
