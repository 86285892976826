import React from 'react';
import { InputGroup } from 'react-bootstrap'
import MultipleValueTextInput from "react-multivalue-text-input";
import classNames from 'classnames';
import _ from 'underscore';
import './index.scss';

const MultiString = ({ handleChange = () => {}, value = '', editable, append, prepend }) => {
    const DELIMITER = ',;:';
    let parsedValues = value.split(DELIMITER);

    return (
        <React.Fragment>
            {
                prepend && <InputGroup.Prepend className={classNames({ readonly: !editable })}>
                    <InputGroup.Text id="basic-addon1">{ prepend }</InputGroup.Text>
                </InputGroup.Prepend>
            }
            <MultipleValueTextInput
                readOnly={!editable}
                className={classNames("form-control")}
                onItemAdded={(item, allItems) => handleChange(allItems.join(DELIMITER))}
                onItemDeleted={(item, allItems) => handleChange(allItems.join(DELIMITER))}
                name="item-input"
                values={_.compact(parsedValues)}
            />
            {
                append && <InputGroup.Append className={classNames({ readonly: !editable })}>
                    <InputGroup.Text id="basic-addon1">{ append }</InputGroup.Text>
                </InputGroup.Append>
            }
        </React.Fragment>
    );
};

export default MultiString;