import React from "react"
import { Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types'
import _ from "underscore"
import {
  validateName,
  validateEmail,
  formatPhoneNumber,
  telephoneCheck
} from "../../utils"

import DashboardCardWithSave from "../../components/DashboardCardWithSave"
import FormControl from "../../components/FormControl"

export default function ContactInformation({ inTraffic, professionalSettings, handleSave, professionalDetails }) {

  const {
    ContactFirstName,
    ContactLastName,
    ContactEmail,
    ContactPhone,
    SameAsPublicProfile
  } = professionalSettings

  const proSettings = {
    ContactFirstName,
    ContactLastName,
    ContactEmail,
    ContactPhone,
    SameAsPublicProfile
  }

  const [values, setValues] = React.useState({
    ...proSettings
  });

  React.useEffect(()=>{
    setValues({
      ...proSettings
    })
  },[professionalSettings])

  const _handleSave = () => {
    handleSave({
      ...professionalSettings,
      ...values
    });
  };

  const _setValues = newVals => {
    setValues({
      ...values,
      ...newVals
    })
  };

  const isEnabled = values.ContactFirstName &&
    values.ContactLastName &&
    validateName(values.ContactFirstName) &&
    validateName(values.ContactLastName) &&
    values.ContactPhone &&
    telephoneCheck(values.ContactPhone) &&
    values.ContactEmail &&
    validateEmail(values.ContactEmail)

  return <DashboardCardWithSave
    handleSave={_handleSave}
    isSaving={inTraffic}
    title="Contact information"
    xs={12}
    hideSave={_.isEqual(values,proSettings)}
    saveDisabled={!isEnabled}
  >
    <Row>
      <Col lg="6" md="8">
        <FormControl
          type="checkboxes"
          options={[
            {
              label: "Use same contact information as public profile",
              checked: values.SameAsPublicProfile
            }
          ]}
          onChange={(e) => {
            if (e.target.checked) {
              _setValues({
                SameAsPublicProfile: e.target.checked,
                ContactFirstName: professionalDetails.FirstName,
                ContactLastName: professionalDetails.LastName,
                ContactPhone: professionalDetails.Phone,
                ContactEmail: professionalDetails.Email
              })
            } else {
              _setValues({ SameAsPublicProfile: e.target.checked })
            }

          }}
        />
        <FormControl
          label="First Name"
          type="text"
          value={values.ContactFirstName || ""}
          onChange={(e) => {
            if(validateName(e.target.value)){
              _setValues({ ContactFirstName: e.target.value })
            }
          }}
          disabled={values.SameAsPublicProfile}
        />
        <FormControl
          label="Last Name"
          type="text"
          value={values.ContactLastName || ""}
          onChange={(e) => {
            if(validateName(e.target.value)){
              _setValues({ ContactLastName: e.target.value })
            }
          }}
          disabled={values.SameAsPublicProfile}
        />
        <FormControl
          label="Phone number"
          type="tel"
          value={values.ContactPhone || ""}
          onChange={(e) => {
            const value = formatPhoneNumber(e.target.value);
            if (
              value ||
              value == ""
            ) {
              _setValues({ ContactPhone: value })
            }
          }}
          disabled={values.SameAsPublicProfile}
        />
        <FormControl
          label="Email"
          type="email"
          value={values.ContactEmail || ""}
          onChange={(e) => _setValues({ ContactEmail: e.target.value })}
          disabled={values.SameAsPublicProfile}
        />
      </Col>
    </Row>
  </DashboardCardWithSave>
}

ContactInformation.propTypes = {
  professionalDetails: PropTypes.object,
  professionalSettings: PropTypes.object,
  inTraffic: PropTypes.bool,
  handleSave: PropTypes.func
}
