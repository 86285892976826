import React from 'react';
import NavLink from "./NavLink";

const AccountOptions = ({ currentPath, auth, handleLogout }) => {
  return (
    <div className={"account-options"}>
      {!auth.isGuest && <NavLink icon={"logout"} label={"Log out"} onClick={handleLogout} path="/logout" />}
      <NavLink icon={"support"} label="Contact us" path="/contact-us" currentPath={currentPath} />
    </div>
  );
};

export default AccountOptions;
