import { ActionTypes } from '../actions';

const defaultState = {
    isSidebarOpen: false,
    isTimelineOpen: false,
    currentPath: window.location.pathname
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.TOGGLE_SIDEBAR:
                return {
                    ...state,
                    isSidebarOpen: !state.isSidebarOpen,
                    isTimelineOpen: false
                };

            case ActionTypes.TOGGLE_TIMELINE:
                return {
                    ...state,
                    isTimelineOpen: !state.isTimelineOpen,
                    isSidebarOpen: false
                };

            case ActionTypes.SET_CURRENT_PATH:
                return {
                    ...state,
                    currentPath: action.payload,
                    isSidebarOpen: false,
                    isTimelineOpen: false
                };

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in userState reducer: ${ error.message || error.code || error }`, error);
    }
};
