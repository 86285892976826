import React from "react";
import "./index.scss";
import { Col, ProgressBar } from "react-bootstrap";
import classNames from "classnames";
import { getIconName } from "../../../utils";
import { ArrowLeft } from "react-bootstrap-icons";

const TimelineSteps = ({
  isOpen,
  estimatedTime,
  steps,
  progress,
  handleSelect,
  selectedStepId,
  doCloseClientTimeline,
}) => {
  return (
    <Col md={3}>
      <div className={classNames("rui__timeline-steps", { "is-open": isOpen })}>
        <div onClick={doCloseClientTimeline} className="arrow-container">
          <ArrowLeft className="back-arrow" size={40} />
        </div>

        <h2>Timeline</h2>
        <h3>{progress}%</h3>
        <ProgressBar now={progress} />
        <p>
          <small>Estimated timeline: {estimatedTime} days</small>
        </p>

        <div>
          <ul className={"incomplete-steps"}>
            {steps.map((step) => (
              <li
                className={classNames({
                  selected:
                    selectedStepId === step.Id ||
                    selectedStepId === step.StepNumber,
                  completed: step.Completed,
                })}
                onClick={() => handleSelect(step.Id || step.StepNumber)}
              >
                {!step.Completed && (
                  <img className={`icon icon-${getIconName(step.StepName)}`} />
                )}
                {step.StepName}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Col>
  );
};

export default TimelineSteps;
