import {call, put, select} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {loadPaymentIntent, saveStripeCustomer} from './networks';

export default [
    [ActionTypes.GET_PAYMENT_INTENT, getPaymentIntent],
    [ActionTypes.DO_SAVE_STRIPE_CUSTOMER, doSaveStripeCustomer]
];

function * getPaymentIntent ({ payload }) {
    yield put(Actions.loadingPaymentIntent());

    const response = yield call(loadPaymentIntent, payload);

    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedPaymentIntent(response.data));
    }
}

function* doSaveStripeCustomer ({ payload }) {
    yield put(Actions.doingSaveStripeCustomer());

    const response = yield call(saveStripeCustomer, payload);

    if (!response.successful) {
        console.log('Error', response.code);
    } else {
        yield put(Actions.doneSaveStripeCustomer({ ...response.data }));
    }
}

