import {call, put, select} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {
  loadProfessionals,
  loadTimelines,
  loadProfessionalsCategories,
  loadProfessionalDetails,
  saveProfessionalDetails,
  loadProfessionalSettings,
  saveProfessionalSettings,
  loadProfessionalReviews
} from './networks';
import _ from "underscore";

export default [
    [ActionTypes.GET_PROFESSIONALS, getProfessionals],
    [ActionTypes.GET_PROFESSIONALS_CATEGORIES, getProfessionalsCategories],
    [ActionTypes.GET_PROFESSIONAL_DETAILS, getProfessionalDetails],
    [ActionTypes.DO_SAVE_PROFESSIONAL_DETAILS, doSaveProfessionalDetails],
    [ActionTypes.GET_PROFESSIONAL_SETTINGS, getProfessionalSettings],
    [ActionTypes.DO_SAVE_PROFESSIONAL_SETTINGS, doSaveProfessionalSettings],
    [ActionTypes.GET_PROFESSIONAL_REVIEWS, getProfessionalReviews]
];

const getTimelineState = (state) => state.timeline;

function * getProfessionals ({ payload }) {
    yield put(Actions.loadingProfessionals());

    let timeline = yield select(getTimelineState);
    let selectedTimelineId = timeline.selectedTimelineId;
    if (!selectedTimelineId) {
        let loadedTimelines = yield call(loadTimelines);
        const timeline = _.first(loadedTimelines.data);
        selectedTimelineId = timeline.Id;
    }

    const response = yield call(loadProfessionals, { TimelineId: selectedTimelineId });

    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedProfessionals(response.data));
    }
}

function * getProfessionalsCategories () {
    yield put(Actions.loadingProfessionalsCategories());
    const response = yield call(loadProfessionalsCategories);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedProfessionalsCategories(response.data));
    }
}

const getOnboardingState = (state) => state.onboarding;

function * getProfessionalDetails () {
    yield put(Actions.loadingProfessionalDetails());

    let onboarding = yield select(getOnboardingState);

    const response = yield call(loadProfessionalDetails,{ProObjectId: onboarding.guid});
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedProfessionalDetails(response.data));
        yield getProfessionalReviews({ProObjectId:response.data.ProObjectId})
    }
}

function *  doSaveProfessionalDetails ({ payload }) {
    yield put(Actions.doingSaveProfessionalDetails());
    const response = yield call(saveProfessionalDetails, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.doneSaveProfessionalDetails(response.data));
    }
}

function * getProfessionalSettings ({ payload }) {
    yield put(Actions.loadingProfessionalSettings());
    const response = yield call(loadProfessionalSettings);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedProfessionalSettings(response.data));
    }
}

function * doSaveProfessionalSettings ({ payload }) {
    yield put(Actions.doingSaveProfessionalSettings());
    console.log(payload)
    const response = yield call(saveProfessionalSettings, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.doneSaveProfessionalSettings(response.data));
    }
}

function * getProfessionalReviews (payload) {
    yield put(Actions.loadingProfessionalReviews(payload));
    const response = yield call(loadProfessionalReviews,payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedProfessionalReviews(response.data.reviews));
    }
}
