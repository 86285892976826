import React, {Component} from 'react';
import Messages from "../pages/Messages";

class LeadsContainer extends Component {
    render() {
        return <Messages />;
    }
}

export default LeadsContainer;
