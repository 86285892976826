import React from 'react';
import { Accordion, Card } from "react-bootstrap";
import classNames from 'classnames';
import _ from 'underscore';
import './index.scss';

const Links = ({ tools }) => {
    const [opened, setOpened] = React.useState(false);

    if (_.isEmpty(tools)) {
        return null;
    }

    return (
        <Accordion className={"rui__dashboard-tools"} defaultActiveKey="0">
            <Accordion.Toggle className={classNames({opened: opened})} as={Card.Header}  variant="link" eventKey="0" onClick={() => setOpened(!opened)}>
                <h6><img className={"icon icon-equilizer"}/>Tools</h6>
                <img className={"icon icon-caret"} alt=""/>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <div className={"flexbox-col"}>
                    {
                        tools.map(tool => <a className={"external-link"} href={tool.Hyperlink} target={"_blank"}>{ tool.DisplayText } <img className={"icon icon-external-link"} alt=""/></a>)
                    }
                </div>
            </Accordion.Collapse>
        </Accordion>
    );
};

export default Links;