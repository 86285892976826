import React from "react";
import _ from "underscore";
import { Col, Row } from "react-bootstrap";
import Details from "../Details";
import Links from "../Links";
import Tools from "../Tools";
import Notes from "../Notes";
import Documents from "../Documents";
import Loader from "../../../utils/Loader";
import "./index.scss";

const Content = ({
  stepInfo,
  isTimelineOpen,
  isTimelineStepLoading,
  isTimelineProfessionalsLoading,
  stepProfessionals,
  handleHire,
  hiringProfessionalId,
  markStepCompleted,
  isMarkingStepCompleted,
  handleSaveDetailFields,
  isSavingDetailFields,
  handleSubmitExternalProfessional,
  selectedStep,
  handleNewNote,
  isSavingNote,
  goBack,
  isGuest,
}) => {
  if (!isGuest && _.isEmpty(stepInfo)) {
    return <Loader />;
  }

  return (
    <Col md={9}>
      <Row className={"rui__timeline-content"}>
        <Col md={isGuest ? 12 : 9}>
          <Details
            isTimelineStepLoading={isTimelineStepLoading}
            isTimelineProfessionalsLoading={isTimelineProfessionalsLoading}
            professionals={stepProfessionals}
            tips={stepInfo.Tips}
            stepInfo={stepInfo}
            handleHire={handleHire}
            hiringProfessionalId={hiringProfessionalId}
            markStepCompleted={markStepCompleted}
            isMarkingStepCompleted={isMarkingStepCompleted}
            handleSaveDetailFields={handleSaveDetailFields}
            isSavingDetailFields={isSavingDetailFields}
            handleSubmitExternalProfessional={handleSubmitExternalProfessional}
            goBack={goBack}
            isGuest={isGuest}
            selectedStep={selectedStep}
            details={stepInfo.Details}
          />
        </Col>
        {!isGuest && (
          <Col md={3}>
            <Links links={stepInfo.Links} />
            <Tools tools={stepInfo.Tools} />
            <Notes
              notes={stepInfo.Notes}
              handleNewNote={handleNewNote}
              isSavingNote={isSavingNote}
            />
            <Documents documents={stepInfo.Documents} />
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default Content;
