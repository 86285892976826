import React from "react";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import classNames from "classnames";

import styles from "./Review.module.scss";

export default function Review({
  score,
  date,
  title,
  comment,
  name,
  location,
}) {
  return (
    <div className={classNames("col-md-6 col-12",styles.reviewContainer)}>
      <div className={styles.review}>
        <div className="row mb-4">
          <p className={styles.scoreChip}>{score.toFixed(1)}</p>{" "}
          <div className={styles.starsContainer}>
            <ReactStars
              count={5}
              value={score}
              isHalf
              size={20}
              edit={false}
              activeColor="#F4BE52"
              color="#CDCDCD"
            />
          </div>
          <p>{moment(date).format("D MMM YYYY")}</p>
        </div>

        <div className="row mb-2">
          <h6>{title}</h6>
        </div>
        <div className="row">
          <p>{comment}</p>
        </div>
        <div className="row mt-4">
          <h6>{name}</h6>
        </div>
        <div className="row">
          <p>{location}</p>
        </div>
      </div>
    </div>
  );
}
