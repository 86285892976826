import { ActionTypes } from "../actions";

const defaultState = {
  leads: [],
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.DONE_GET_LEADS:
        return {
          ...state,
          leads: action.payload,
        };

      default:
        return state;
    }
  } catch (error) {
    console.error(
      `Error in clients reducer: ${error.message || error.code || error}`,
      error
    );
  }
};
