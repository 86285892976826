import React from 'react';
import {Row, Button, Col, Modal, Form, Tabs, Tab} from 'react-bootstrap';
import ProfessionalDetail from '../../../../utils/ProfessionalDetail';
import _ from 'underscore';
import './index.scss';
import {formatPhoneNumber, getIconName} from "../../../../utils";
import {isMobile} from "react-device-detect";
import Loader from "../../../../utils/Loader";

const ProfessionalDetails = ({ professionals, handleHire, hiringProfessionalId, handleSubmit, isTimelineProfessionalsLoading }) => {
    const [modalVisible, setModalVisible] = React.useState(0);
    const [externalProfessional, setExternalProfessional] = React.useState({});

    if (_.isEmpty(professionals)) {
        return null;
    }

    const _setModalVisible = (visible, categoryId) => {
        setExternalProfessional({ CategoryId: categoryId });
        setModalVisible(1);
    };

    const _setExternalProfessional = (key, value) => {
        setExternalProfessional({ ...externalProfessional, [key]: value });
    };

    const _handleSubmit = () => {
        handleSubmit(externalProfessional);
        setModalVisible(0);
    };

    const isEnabled = externalProfessional.FirstName && externalProfessional.LastName
                        && externalProfessional.Email && externalProfessional.Phone
                        && externalProfessional.CompanyName && externalProfessional.Address;

    if (isTimelineProfessionalsLoading) {
        return <Loader />
    }

    return (
        <div className={"rui__professional-details"}>
            <h6>Professionals that we found for you:</h6>
            <Tabs defaultActiveKey={`step-1`}>
            {
                professionals.map((professional, id) => {
                    return (
                            <Tab eventKey={`step-${id + 1}`} title={professional.CategoryName}>
                                <Row>
                                    <Col className={"content"}>
                                        {
                                            professional.ProfessionalExternal && <ProfessionalDetail
                                                                                        icon={getIconName(professional.CategoryName)}
                                                                                        handleHire={professionalId => handleHire(professional.CategoryId, professionalId)}
                                                                                        hired={professional.ProfessionalExternal.Id === professional.HiredProfessionalExternalId}
                                                                                        isHiring={hiringProfessionalId === professional.ProfessionalExternal.Id}
                                                                                        professional={professional.ProfessionalExternal} />
                                        }
                                        {
                                            professional.Professionals && professional.Professionals.map(p => <ProfessionalDetail
                                                                                                                        icon={getIconName(professional.CategoryName)}
                                                                                                                        handleHire={professionalId => handleHire(professional.CategoryId, professionalId)}
                                                                                                                        hired={p.Id === professional.HiredProfessionalId}
                                                                                                                        isHiring={hiringProfessionalId === p.Id}
                                                                                                                        professional={p} />)
                                        }
                                    </Col>
                                </Row>
                                <Button className={"add-lender-details"} onClick={() => _setModalVisible(1, professional.CategoryId)}><img className={"icon icon-plus"} alt=""/>Add Professional Details Manually</Button>
                            </Tab>
                    )
                })
            }
            </Tabs>

            {/*<Row>*/}
            {/*    <Col xs={12}>*/}
            {/*        <a href="">Show All Lenders <img className={"icon icon-caret"} alt=""/></a>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            <Modal show={modalVisible} onHide={() => setModalVisible(0)} size="lg"
                   aria-labelledby="contained-modal-title-center"
                   centered={!isMobile}>
                <Modal.Header closeButton>
                    <Modal.Title>Lender Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" value={externalProfessional.FirstName} onChange={e => _setExternalProfessional("FirstName", e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" value={externalProfessional.LastName} onChange={e => _setExternalProfessional("LastName", e.target.value)}/>
                            </Form.Group>

                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" value={externalProfessional.Email} onChange={e => _setExternalProfessional("Email", e.target.value)}/>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control type="text" value={externalProfessional.CompanyName} onChange={e => _setExternalProfessional("CompanyName", e.target.value)}/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Col md={3}>
                                <Form.Label>Phone number</Form.Label>
                                <Form.Control type="tel" value={externalProfessional.Phone} onChange={e => _setExternalProfessional("Phone", formatPhoneNumber(e.target.value))}/>
                            </Col>

                            <Col md={9}>
                                <Form.Label>Address</Form.Label>
                                <Form.Control placeholder="123 Main St" value={externalProfessional.Address} onChange={e => _setExternalProfessional("Address", e.target.value)}/>
                            </Col>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalVisible(0)} variant="outline-primary">
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => _handleSubmit()} disabled={!isEnabled}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProfessionalDetails;