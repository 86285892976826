import React from 'react';
import {FormControl} from "react-bootstrap";

const Options = ({ handleChange = () => {}, value, editable, options }) => {
    return (
        <React.Fragment>
            <FormControl as="select" className={'mb-3'}
                         className={"form-control"}
                         readOnly={!editable}
                         onChange={e => handleChange(e.target.value)}
                         defaultValue={value}>
                <option selected disabled>Select</option>
                {
                    options.map(opt => <option selected={opt === value} value={opt}>{ opt }</option> )
                }
            </FormControl>
        </React.Fragment>
    );
};

export default Options;