import React from 'react';
import { Accordion, Card } from "react-bootstrap";
import classNames from 'classnames';
import './index.scss';
import _ from "underscore";

const Notes = ({ notes, handleNewNote, isSavingNote }) => {
    const [opened, setOpened] = React.useState(false);
    const [newNote, setNewNote] = React.useState(false);
    let newNoteBox = React.useRef(null);

    const _setNewNote = () => {
        setNewNote(true);
        setTimeout(() => newNoteBox.current.focus(), 0);
    };

    const _handleNewNote = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleNewNote(e.target.innerText);
            newNoteBox.current.innerText = "";
            setNewNote(false);
        }
    };

    let sortedNotes = _.chain(notes).sortBy('Id').reverse().value();

    return (
        <Accordion className={"rui__timeline-notes"} defaultActiveKey="0">
            <Accordion.Toggle className={classNames({opened: opened})} as={Card.Header}  variant="link" eventKey="0" onClick={() => setOpened(!opened)}>
                <h6><img className={"icon icon-note"}/>
                    Notes
                    {
                        isSavingNote && <img src="/images/loading.gif" className={"loader"} alt="Processing..."/>
                    }
                </h6>
                <img className={"icon icon-caret"} alt=""/>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <React.Fragment>
                    <a className={"new"} onClick={() => _setNewNote(true)}><img className={"icon icon-plus"} alt=""/>New note</a>
                    {
                        newNote
                            ? <div className={"new-note-box mb-2"} contentEditable ref={newNoteBox} onKeyDown={e => _handleNewNote(e)}></div>
                            : null
                    }
                    {
                        sortedNotes.map((note, i) => <p key={i} className={"note"}>{note.Text}</p>)
                    }
                </React.Fragment>
            </Accordion.Collapse>
        </Accordion>
    );
};

export default Notes;