import { ActionTypes } from "../actions";

const defaultState = {
  inTraffic: false,
  openTimeline: false,
  clients: [],
  selectedClientUserObjectId: null,
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.DONE_GET_CLIENTS:
        return {
          ...state,
          clients: action.payload,
        };

      case ActionTypes.DOING_GET_CLIENT_TIMELINE:
        localStorage.setItem("selectedClientUserObjectId", action.payload);
        return {
          ...state,
          selectedClientUserObjectId: action.payload,
        };
      case ActionTypes.DONE_GET_CLIENT_TIMELINE:
        return {
          ...state,
          openTimeline: true,
        };
      case ActionTypes.DONE_CLOSE_CLIENT_TIMELINE:
        return {
          ...state,
          openTimeline: false,
        };

      default:
        return state;
    }
  } catch (error) {
    console.error(
      `Error in clients reducer: ${error.message || error.code || error}`,
      error
    );
  }
};
