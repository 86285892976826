import React, {Component} from 'react';
import classNames from 'classnames';
import { InputGroup, Row, Col, FormLabel } from "react-bootstrap";
import DateTime from "../../../../utils/Fields/DateTime";
import MultiString from "../../../../utils/Fields/MultiString";
import String from "../../../../utils/Fields/String";
import Number from "../../../../utils/Fields/Number";
import Options from "../../../../utils/Fields/Options";
import './index.scss';
import {getIconName} from "../../../../utils";
import Loader from "../../../../utils/Loader";

class Details extends Component {
    state = {
        editable: false,
        fieldMap: this.props.fields.reduce((allFields, field) => {
            allFields[field.Name] = field.Value;
            return allFields;
        }, {})
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.inTraffic !== nextProps.inTraffic && !nextProps.inTraffic) {
            this.setEditable(0);
        }
    }

    setEditable (editable) {
        this.setState({ editable: editable });
    }

    setFieldVal (name, value) {
        this.setState({ fieldMap: { ...this.state.fieldMap, [name]: value } });
    }

    handleSave () {
        const parsedFields = this.props.fields.map(field => {
            field.Value = this.state.fieldMap[field.Name];
            return field;
        });

        this.props.handleSave(parsedFields);
    }

    render() {
        const setEditable = this.setEditable.bind(this);
        const setFieldVal = this.setFieldVal.bind(this);
        const handleSave = this.handleSave.bind(this);
        const { editable, fieldMap } = this.state;
        const { fields, label, inTraffic, icon } = this.props;

        return (
            <div className={classNames("rui__details", { "read-only": !editable })}>
                <Row>
                    <Col xs={6}>
                        <h6>
                            { icon &&  <img className={`icon icon-${getIconName(label)}`} />}
                            { label }
                        </h6>
                    </Col>
                    <Col xs={6} className={"edit-container"}>
                        {
                            !editable
                                ? <a onClick={() => setEditable(1)}><img className={"icon icon-edit"}/>Edit</a>
                                : <a onClick={() => setEditable(0)} onClick={handleSave}>
                                    {
                                        inTraffic
                                            ? <img src="/images/loading.gif" className={"loading"} alt="Processing..."/>
                                            : "Save"
                                    }
                                </a>
                        }
                    </Col>
                </Row>
                <Row className={"all-detail-fields"}>
                    {
                        fields.map(field => {
                            return <Col md={field.Type === 'multistring' ? 12 : 4} className={"details-input"}>
                                <FormLabel>{ field.Name }</FormLabel>
                                <InputGroup className="mb-3">
                                    {
                                        field.Type === "datetime" && <DateTime editable={editable}
                                                                               value={fieldMap[field.Name]}
                                                                               handleChange={val => setFieldVal(field.Name, val)}/>
                                    }
                                    {
                                        field.Type === "multistring" && <MultiString editable={editable}
                                                                                     append={field.Append}
                                                                                     prepend={field.Prepend}
                                                                                     handleChange={val => setFieldVal(field.Name, val)}
                                                                                     value={fieldMap[field.Name]}/>
                                    }
                                    {
                                        field.Type === "string" && <String editable={editable}
                                                                           append={field.Append}
                                                                           prepend={field.Prepend}
                                                                           handleChange={val => setFieldVal(field.Name, val)}
                                                                           value={fieldMap[field.Name]}/>
                                    }
                                    {
                                        field.Type === "number" && <Number editable={editable}
                                                                           append={field.Append}
                                                                           prepend={field.Prepend}
                                                                           value={fieldMap[field.Name]}
                                                                           handleChange={val => setFieldVal(field.Name, val)}
                                                                           condition={field.Condition}/>
                                    }
                                    {
                                        field.Type === "option" && <Options editable={editable}
                                                                            options={field.Options}
                                                                            handleChange={val => setFieldVal(field.Name, val)}
                                                                            value={fieldMap[field.Name]}/>
                                    }
                                </InputGroup>
                            </Col>
                        })
                    }
                </Row>

            </div>
        );
    }
}

export default Details;