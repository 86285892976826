import { ActionTypes } from '../actions';

const defaultState = {
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADING_SUMMARY:
                return {
                    ...state,
                    isLoading: true
                };

            case ActionTypes.LOADED_SUMMARY:
                return {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                    summaryDetails: action.payload
                };

            case ActionTypes.DOING_SAVE_SUMMARY:
                return {
                    ...state,
                    inTraffic: true
                };

            case ActionTypes.DONE_SAVE_SUMMARY:
                return {
                    ...state,
                    inTraffic: false
                };

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in summaryState reducer: ${ error.message || error.code || error }`, error);
    }
};
