import React from "react";
import PropTypes from 'prop-types'
import classNames from "classnames"
import { Modal, Row, Col } from "react-bootstrap";
import moment from "moment"

import styles from './HomebuyerModal.module.scss'
import ReviewsComponent from "../Reviews";

export default function HomebuyerModal({ show, handleClose, professionalDetails, categories }) {
  const {
    PhotoLink,
    FirstName,
    LastName,
    CompanyName,
    Address,
    NoPhysicalAddress,
    //  Email,
    //  Phone,
    Intro,
    CategoryId,
    WorkingSince,
    Website,
    ServiceAreas,
    DisplayContact,
    Reviews
  } = professionalDetails

  const fallBackImg = "https://netstock-europe.com/wp-content/uploads/2016/09/fg-avatar-anonymous-user-retina-300x300.png"

  const Category = (categories || []).find(c => c.CategoryId === CategoryId) ?.CategoryName

  return <Modal show={show} onHide={handleClose} className={styles.root}>
    <Modal.Header closeButton>
      <Row>
        <Col md="auto text-center mb-4 d-none d-md-flex">
          <img src={PhotoLink || fallBackImg} alt="" className={styles.profilePic} />
        </Col>
        <Col md="auto" xs={12} className={classNames("flex-md-grow-1 flex-md-shrink-1", styles.content)}>
          <div className="d-flex align-items-center mb-2 mb-md-0">
            <img src={PhotoLink || fallBackImg} alt="" className={classNames(styles.profilePic, "d-md-none")} />
            <div>
              <h4>{FirstName && LastName && DisplayContact ? `${FirstName} ${LastName}` : CompanyName}</h4>
              <h5>{`${Category || "[Business Category]"}${FirstName && LastName && DisplayContact? ` • ${CompanyName}` : ""}`}</h5>
            </div>
          </div>
          {
            moment(WorkingSince)._isValid &&
            <div className="d-flex align-items-center mb-2">
            <h6 className="mb-0"><b>Experience:</b> {moment(WorkingSince).fromNow().slice(0, -3)}</h6>
          </div>
        }
          <div className="d-flex align-items-center mb-2">
            <h6 className="mb-0"><b>Areas served:</b> {(ServiceAreas[Object.keys(ServiceAreas)[0]] || []).join(", ")}</h6>
          </div>
          {
            Website &&
            <div className="d-flex align-items-center mb-2">
              <h6 className="mb-0"><b>Website:</b> {Website}</h6>
            </div>
          }
          {
            Intro ?
              <p className="mt-4">
              {Intro.split('\n').map((item, key) =>  <React.Fragment key={key}>{item}<br /></React.Fragment>)}
              </p> :
              <p>[Intro]</p>
          }
          {
            !NoPhysicalAddress &&
            <div className="d-flex align-items-center mb-0">
              <i className="icon icon-pin mr-2" />
              <h6 className="mb-0">{Address || "[Address]"}</h6>
            </div>
          }
          {/*<div className="d-flex align-items-center mb-2">
            <i className="icon icon-mail mr-2" />
            <h6 className="mb-0">{Email}</h6>
          </div>
          <div className="d-flex align-items-center">
            <i className="icon icon-phone mr-2" />
            <h6 className="mb-0">{Phone}</h6>
          </div>*/}
        </Col>
      </Row>
    </Modal.Header>
    <Modal.Body className={styles.reviews}>
      <h6 className="mb-3">Reviews</h6>
      <ReviewsComponent reviews={Reviews||[]}/>
    </Modal.Body>
  </Modal>
}

HomebuyerModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  professionalDetails: PropTypes.object,
  categories: PropTypes.array
}
