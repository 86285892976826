/**
 * Reducers output the Redux state (models) for the app. They do not alter the
 * existing state, but generate modified state objects from the old state in
 * response to actions.
 *
 * http://redux.js.org/docs/basics/Reducers.html
 *
 **/
import { combineReducers } from "redux";
import onboarding from "./onboarding";
import timeline from "./timeline";
import sidebar from "./sidebar";
import toasts from "./toasts";
import auth from "./auth";
import professionals from "./professionals";
import dashboard from "./dashboard";
import summary from "./summary";
import documents from "./documents";
import settings from "./settings";
import clients from "./clients";
import leads from "./leads";

/*
 * Reducer aggregator
 */
export default combineReducers({
  /*
   * Populate reducers
   */
  onboarding,
  sidebar,
  timeline,
  toasts,
  auth,
  professionals,
  dashboard,
  summary,
  documents,
  settings,
  clients,
  leads,
});
