import React from "react";
import queryParams from "queryparams";

const Dashboard = ({ onboarding }) => {
  return (
    <div id={"rui__dashboard-container"}>
      {queryParams()["onboarding"] === "complete" ? (
        <>
          <div
            className={"centered onboarding-complete "}
            style={{ paddingTop: 60 }}
          >
            <p>
              Thanks for completing your profile. Your account and timeline are
              now ready. You will receive an email with details on how to access
              our platform.
            </p>
          </div>

          <div className={"centered "} style={{ paddingTop: 6 }}>
            <p className="mt-3 mb-0" style={{ textAlign: "center" }}>
              Call us 551-998-6154
            </p>

            <p
              className="mt-0 "
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              <a
                onClick={() =>
                  window.open(
                    "https://www.hyponia.com/privacy-policy",
                    "_blank"
                  )
                }
                className="onboarding-link"
              >
                {" "}
                Privacy Policy
              </a>
               | {" "}
              <a
                onClick={() =>
                  window.open("https://www.hyponia.com/terms-of-use", "_blank")
                }
                className="onboarding-link"
              >
                {" "}
                Terms of Use 
              </a>
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Dashboard;
