import React from "react";
import PropTypes from 'prop-types'
import DashboardCard from "../DashboardCard"
import { Button } from "react-bootstrap";

import classNames from "classnames"

import styles from './DashboardCardWithSave.module.scss'

export default function Layout({ children, isSaving, handleSave, beta, hideSave = false, saveDisabled, ...other }) {

  return <DashboardCard {...other} beta={beta}>
    {children}
    {
      !beta && !hideSave?
      <Button
        variant="primary"
        className={classNames("position-absolute",styles.saveButton)}
        onClick={handleSave}
        disabled={saveDisabled}
      >
        {isSaving ? <img
          src="/images/loading.gif"
          alt="Processing..."
        />
          : "Save"
        }
      </Button>:
      null
    }
  </DashboardCard>

}

Layout.propTypes = {
  children: PropTypes.node,
  isSaving: PropTypes.bool,
  hideSave: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  handleSave: PropTypes.func,
  beta: PropTypes.bool
}
