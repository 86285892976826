import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import Tips from "./Tips";
import ProfessionalDetails from "./ProfessionalDetails";
import Details from "./Details";
import "./index.scss";
import Loader from "../../../utils/Loader";
import _, { isEmpty } from "underscore";
import Info from "../../../utils/timeline_info";
import DetailContent from "./DetailContent";

const DetailsTabs = ({
  details,
  professionals,
  tips,
  isLoading,
  handleHire,
  hiringProfessionalId,
  handleSave,
  isSavingDetailFields,
  handleSubmitExternalProfessional,
  isTimelineStepLoading,
  stepInfo,
  isTimelineProfessionalsLoading,
}) => {
  if (isEmpty(details) || isEmpty(professionals)) {
    return null;
  }

  let allFields = details.DetailFields;

  const _handleSave = (key, fieldsToSave) => {
    const newDetails = {
      ...details,
      DetailFields: {
        ...details.DetailFields,
        [key]: fieldsToSave,
      },
    };

    handleSave(newDetails);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div id={"details-container"}>
      <Tabs>
        <Tab eventKey="info" title="Info">
          <Row>
            <Col className={"content"}>
              <DetailContent Info={Info} StepNumber={stepInfo.StepNumber} />
            </Col>
          </Row>
        </Tab>

        {_.isEmpty(tips) ? null : (
          <Tab eventKey="stress" title="Tips">
            <Row>
              <Col className={"content"}>
                <Tips tips={tips} />
              </Col>
            </Row>
          </Tab>
        )}

        <Tab eventKey="time" title="Details">
          {isTimelineStepLoading ? (
            <Row>
              <Col>
                <Loader />
              </Col>
            </Row>
          ) : (
            Object.keys(allFields).map((key) => (
              <Row>
                <Col className={"content"}>
                  <Details
                    label={key}
                    fields={allFields[key]}
                    inTraffic={isSavingDetailFields}
                    handleSave={(fieldValues) => _handleSave(key, fieldValues)}
                    icon={false}
                    isTimelineStepLoading={isTimelineStepLoading}
                  />
                </Col>
              </Row>
            ))
          )}
        </Tab>

        <Tab eventKey="money" title="Professionals">
          <Row>
            <Col className={"content"}>
              <ProfessionalDetails
                hiringProfessionalId={hiringProfessionalId}
                handleHire={handleHire}
                professionals={professionals}
                handleSubmit={handleSubmitExternalProfessional}
                isTimelineProfessionalsLoading={isTimelineProfessionalsLoading}
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
};

export default DetailsTabs;
