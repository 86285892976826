import React from 'react';
import {Col} from "react-bootstrap";

const Question = ({ question }) => {
    return (
            <h5 className="question">{ question }</h5>
    );
};

export default Question;
