import React, { Component } from "react";
import Leads from "../pages/Leads";
import { connect } from "react-redux";
import { Actions } from "../actions";

class LeadsContainer extends Component {
  componentWillMount = () => {
    this.props.doGetLeads();
  };

  render() {
    return <Leads {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.leads,
  };
};
export default connect(mapStateToProps, Actions)(LeadsContainer);
