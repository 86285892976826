// global location
/*eslint no-mixed-operators: "off"*/
import queryString from 'query-string';
import _ from 'underscore';

// Generic utility functions
import { assign, clone } from 'lodash';


// Is a value string or null?
export function isStringOrNull (value) {
    return !value || typeof value === 'string';
}

// If the value has an id property return its id, else return the value
export function getId (value) {
    return value && value.id || value;
}

// Sort objects by id property of
export function sortById (a = {}, b = {}) {
    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
}

// Sort objects by name property
export function sortByName (a = {}, b = {}) {
    a = a.name && a.name.toUpperCase();
    b = b.name && b.name.toUpperCase();

    return (a > b) ? 1 : (a < b) ? -1 : 0;
}

// Return a new clone of the object with args assigned to it
export function compose (obj, ...args) {
    return assign(clone(obj), ...args);
}

// Convert a space seperated string to an upper snake cased string
// e.g. "cat and dog" -> "CAT_AND_DOG"
export function upperSnakeCase (string) {
    return string.split(' ').join('_').toUpperCase();
}

// Convert an underscore or space sepertated sting to a camel-cased string
// e.g. "eat RHUBARB_pie" -> "eatRhubarbPie"
export function camelCase (string) {
    let words = string.toLowerCase().split(/_| /);
    return words.reduce((word, next) =>
        word + next[0].toUpperCase() + next.slice(1));
}

// Log message with a timestamp.
export const log = logFactory();

// Log error with a timestamp.
export const logError = logFactory('error');

// Create new loggers with the specified severity
export function logFactory (severity = 'log') {
    return (...messages) => {
        let timestamp = new Date().toISOString();
        console[severity](timestamp, ...messages);
    };
}

export function replacePatternToComponent (text, pattern, Component) {
    const splitText = text.split(pattern);
    const matches = text.match(pattern);

    if (splitText.length <= 1) {
        return text;
    }

    return splitText.reduce((arr, element) => {
            if (!element) return arr;

            if(matches.includes(element)) {
                return [...arr, Component];
            }

            return [...arr, element];
        },
        []
    );
};

/**
 * getQueryParams
 * Returns query params object
 * e.g. "?q=123&b=321" -> {q: 123, b:321}
 *
 **/
export function getQueryParams() {
    let qParams = {
        ...queryString.parse(window.location.search),
        ...queryString.parse(window.location.hash)
    };

    return qParams;

}
export function getAllStates() {
    return [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District Of Columbia",
        "Federated States Of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
         "Indiana",
         "Iowa",
         "Kansas",
         "Kentucky",
         "Louisiana",
         "Maine",
         "Marshall Islands",
         "Maryland",
         "Massachusetts",
         "Michigan",
         "Minnesota",
         "Mississippi",
         "Missouri",
         "Montana",
         "Nebraska",
         "Nevada",
         "New Hampshire",
         "New Jersey",
         "New Mexico",
         "New York",
         "North Carolina",
         "North Dakota",
         "Northern Mariana Islands",
         "Ohio",
         "Oklahoma",
        "Oregon",
         "Palau",
         "Pennsylvania",
         "Puerto Rico",
         "Rhode Island",
        "South Carolina",
         "South Dakota",
        "Tennessee",
     "Texas",
         "Utah",
         "Vermont",
        "Virgin Islands",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
    ];
}

/**
 * getQueryParam
 * Returns specified query param value
 * e.g. getQueryParams(q) => 123
 *
 **/

export function getQueryParam(param) {
    return getQueryParams()[param];
}

/**
 * stringifyQueryData
 * Returns stringified data for a specific query param
 * e.g. stringifyQueryParams([a, b]) => "a,b"
 *
 **/

function _stringifyQueryData (data) {
    if (typeof data === "string") { // String
        return data;
    }

    if (typeof data === "object" && typeof data.length !== "undefined") { // Array
        return data.join(",");
    }

    if (typeof data === "object") { // Object
        return Object.keys(data).reduce((filtered, d) => {
            if (data[d]) {
                filtered.push(d);
            }
            return filtered;
        }, []).join(",");
    }
}


/**
 * stringifyQueryParams
 * Returns stringified query params to be consumed by the URIs
 * e.g. stringifyQueryParams({ ...value }) => "?key=value&..."
 *
 **/

export function stringifyQueryParams (queryParams = {}) {
    return Object.keys(queryParams).map(k => `${k}=${_stringifyQueryData(queryParams[k])}`).join("&");
}

/**
 * constructUrl
 * Returns constructed url stitched with endpoint uri and stringified query params.
 * e.g. constructUrl("https://blahblah.com", "myname", { ...values }) => "https://blahblah.com/myname?key=value&..."
 *
 **/

export function constructUrl (endpoint, uri, queryParams) {
    let queryString = stringifyQueryParams(queryParams);
    return `${endpoint}/${uri}?${queryString || ''}`;
}

export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const validateName = (name) => {
  const has_number = /\d/.test(name);
  const has_special_character = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
    name
  );

  return !(has_special_character || has_number)
}

export const telephoneCheck = (str) => {
  return /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(str);
}

export const yearCheck = (str) => {
  return /^[0-9]{0,4}$/.test(str)
}


export function numberWithCommas(x) {
    return x.toString().split(',').join('').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  let num = "";

  if (phoneNumberString.length === 13) {
    num = phoneNumberString.replace("(", "");
    num = num.replace(")", "");
    num = num.replace("-", "");
    num = num.replace(" ", "");
    return num;
  }

  if (phoneNumberString.length < 14) {
    if (!isNaN(phoneNumberString)) {
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return phoneNumberString;
    } else if (phoneNumberString === "") {
      return "";
    }
  }
}

export function getFormattedAddress(address) {
    var formattedAddress = {};

    _.mapObject(address['address_components'], component => {
        if (!_.isEmpty(component['types'])) {
            if (_.intersection(component['types'], ['locality', 'sublocality']).length) {
                formattedAddress['City'] = component['long_name']
            }

            if (_.intersection(component['types'], ['administrative_area_level_1']).length) {
                formattedAddress['State'] = component['long_name']
            }

            if (_.intersection(component['types'], ['postal_code']).length) {
                formattedAddress['ZipCode'] = component['short_name']
            }

            if (_.intersection(component['types'], ['country']).length) {
                formattedAddress['Country'] = component['short_name']
            }

            if (_.intersection(component['types'], ['street_number']).length) {
                formattedAddress['AddressLine'] = formattedAddress['AddressLine'] ? component['long_name'] + ' ' + formattedAddress['AddressLine'] : component['long_name'];
            }

            if (_.intersection(component['types'], ['route']).length) {
                formattedAddress['AddressLine'] = formattedAddress['AddressLine'] ? formattedAddress['AddressLine'] + ' ' + component['long_name'] : component['long_name'];
            }
        }
    });

    if (_.isEmpty(formattedAddress))
        return null;
    else return formattedAddress;
}

export function stringifyAddress (address) {
    if (typeof address !== "object") { return address; }
    var parsedAddress = "";
    parsedAddress += address.AddressLine || "";
    parsedAddress += address.AddressLine2 ? ` ${address.AddressLine2}` : "";
    parsedAddress += address.City ? `, ${address.City}` : "";
    parsedAddress += address.State ? `, ${address.State}`: "";
    parsedAddress += address.Country ? ` ${address.Country}`: "";
    parsedAddress += address.ZipCode ? ` ${address.ZipCode}`: "";
    return parsedAddress;
}

export function roundByNum(num, rounder) {
    var multiplier = 1/(rounder||0.5);
    return Math.round(num*multiplier)/multiplier;
}

export function getIconName (label) {
    return label.toLowerCase().split(" ").join("-");
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
