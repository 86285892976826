import { call, put, select } from "redux-saga/effects";
import { Actions, ActionTypes } from "../actions";
import _ from "underscore";
import {
  loadTimelines,
  loadDefaultTimeline,
  loadStepInfo,
  createTimeline,
  loadStepProfessionals,
  saveStep,
  hireProfessional,
  saveExternalProfessional,
} from "./networks";

export default [
  [ActionTypes.GET_TIMELINES, getTimelines],
  [ActionTypes.GET_DEFAULT_TIMELINE, getDefaultTimeline],
  [ActionTypes.GET_STEP_INFO, getStepInfo],
  [ActionTypes.GET_STEP_PROFESSIONALS, getStepProfessionals],
  [ActionTypes.DO_SAVE_NOTE, doSaveNote],
  [ActionTypes.DO_HIRE_PROFESSIONAL, doHireProfessional],
  [ActionTypes.DO_MARK_STEP_COMPLETED, doMarkStepCompleted],
  [ActionTypes.DO_SAVE_DETAIL_FIELDS, doSaveDetailFields],
  [ActionTypes.DO_SAVE_EXTERNAL_PROFESSIONAL, doSaveExternalProfessional],
];

const getTimelineState = (state) => state.timeline;

function* getTimelines({ payload }) {
  yield put(Actions.loadingTimelines());

  const response = yield call(loadTimelines);

  if (!response.successful) {
    yield put(Actions.handleErrors(response));
    yield put(Actions.getDefaultTimeline());
  } else {
    if (_.isEmpty(response.data)) {
      yield put(Actions.getDefaultTimeline());
    } else {
      const timeline = _.first(response.data);
      const firstSelectedStep = _.chain(timeline.Steps)
        .filter((step) => !step.Completed)
        .first()
        .value();
      const stepId =
        (firstSelectedStep && firstSelectedStep.Id) ||
        _.last(timeline.Steps).Id;

      yield put(Actions.loadedTimelines(response.data));
      yield put(
        Actions.getStepInfo({ StepId: stepId, TimelineId: timeline.Id })
      );
      yield put(
        Actions.getStepProfessionals({
          StepId: stepId,
          TimelineId: timeline.Id,
        })
      );
    }
  }
}

function* getDefaultTimeline({ payload }) {
  yield put(Actions.loadingDefaultTimeline());

  const response = yield call(loadDefaultTimeline);

  if (!response.successful) {
    console.log("Error", response.code);
    yield put(Actions.handleErrors(response));
  } else {
    const timeline = _.first(response.data);
    const timelineId = timeline.Id;
    const stepId = _.first(timeline.DefaultSteps).DefaultStepId;
    yield put(Actions.loadedDefaultTimeline(response.data));
    yield put(
      Actions.getStepInfo({ TimelineId: timelineId, DefaultStepId: stepId })
    );
    yield put(
      Actions.getStepProfessionals({ TimelineId: timelineId, StepId: stepId })
    );
  }
}

function* getStepInfo({ payload }) {
  yield put(Actions.loadingStepInfo(payload));

  const response = yield call(loadStepInfo, payload);

  if (!response.successful) {
    console.log("Error", response.code);
    yield put(Actions.handleErrors(response));
    yield put(Actions.errorLoadingStepInfo());
  } else {
    yield put(Actions.loadedStepInfo({ ...response.data }));
    yield put(
      Actions.getStepProfessionals({
        StepId: payload.StepId || payload.DefaultStepId,
        TimelineId: payload.TimelineId,
      })
    );
  }
}

function* getStepProfessionals({ payload }) {
  yield put(Actions.loadingStepProfessionals({ StepId: payload.StepId }));
  const response = yield call(loadStepProfessionals, payload);

  if (!response.successful) {
    yield put(Actions.handleErrors(response));
    yield put(Actions.errorLoadingStepProfessionals());
  } else {
    yield put(Actions.loadedStepProfessionals(response.data));
  }
}

function* doSaveNote({ payload: note }) {
  yield put(Actions.doingSaveNote());

  let timeline = yield select(getTimelineState);

  const payload = {
    ...timeline.stepInfo,
    Notes: [
      ...timeline.stepInfo.Notes,
      {
        Deleted: false,
        StepId: timeline.selectedStepId,
        Text: note,
        TimelineId: timeline.selectedTimelineId,
      },
    ],
  };

  const response = yield call(saveStep, payload);

  if (!response.successful) {
    console.log("Error", response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.doneSaveNote({ ...response.data }));
  }
}

function* doHireProfessional({ payload }) {
  yield put(Actions.doingHireProfessional(payload.HiredProfessionalId));

  const response = yield call(hireProfessional, payload);

  if (!response.successful) {
    console.log("Error", response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.doneHireProfessional({ ...response.data }));
  }
}

function* doMarkStepCompleted({ payload: completed }) {
  yield put(Actions.doingMarkStepCompleted());

  let timeline = yield select(getTimelineState);

  const payload = {
    ...timeline.stepInfo,
    Completed: completed,
  };

  const response = yield call(saveStep, payload);

  if (!response.successful) {
    console.log("Error", response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.doneMarkStepCompleted({ ...response.data }));
  }
}

function* doSaveDetailFields({ payload: Details }) {
  yield put(Actions.doingSaveDetailFields());

  let timeline = yield select(getTimelineState);
  const payload = {
    ...timeline.stepInfo,
    Details,
  };

  const response = yield call(saveStep, payload);

  if (!response.successful) {
    console.log("Error", response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.doneSaveDetailFields({ ...response.data }));
  }
}

function* doSaveExternalProfessional({ payload }) {
  yield put(Actions.doingSaveExternalProfessional());
  yield put(Actions.doShowMessage({ message: "Saving professional..." }));
  const response = yield call(saveExternalProfessional, payload);

  if (!response.successful) {
    console.log("Error", response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.doneSaveExternalProfessional({ ...response.data }));
    yield put(Actions.doneShowMessage());
  }
}
