import React from "react"
import PropTypes from 'prop-types'
import { Row, Col } from "react-bootstrap";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import _ from "underscore";

import DashboardCardWithSave from "../../components/DashboardCardWithSave"
import FormControl from "../../components/FormControl"

export default function LeadSettings({ inTraffic, handleSave, saveCard, professionalSettings }) {

  const [values, setValues] = React.useState({
    ...professionalSettings
  });

  const _handleSave = () => {
    handleSave(values);
  };

  const _setValues = newVals => {
    setValues({
      ...values,
      ...newVals
    })
  };

  //Stripe
  const elements = useElements();
  const stripe = useStripe();
  const [cardValid, setCardValid] = React.useState(0);

  const { StripeDetails = {} } = professionalSettings;
  const { sources = {} } = StripeDetails;
  const savedCard = sources.data && _.findWhere(sources.data, { id: StripeDetails.default_source });

  const _saveCard = () => {
    let cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then(result => {
      if (result.error) {
        return console.log("Something went wrong while saving the card. Please contact administrator");
      }

      saveCard(result.token)
    })
  };

  const handleCardChange = (event) => {
    if (!event.complete) {
      setCardValid(0);
    } else {
      setCardValid(1);
    }
  };

  const _handleCombinedSave = () => {
    _handleSave()
    //_saveCard()
  }

  return <DashboardCardWithSave handleSave={_handleCombinedSave} isSaving={inTraffic} title="Billing" xs={12} beta>
    <Row>
      <Col lg="6" md="8">
        <FormControl
          label="Monthly budget"
          type="prepend"
          prependText="$"
          value={values.DailyBudget}
          onChange={e => _setValues({ DailyBudget: e.target.value })}
          disabled
        />
        <FormControl
          label="Payment information"
          type="info"
          value="Free for now"
          className="mb-0"
        />
        {/*<FormControl
          label="Payment information"
          type="info"
          value={!_.isEmpty(savedCard) ? `${savedCard.brand} **** **** ${savedCard.last4}` : "No Card Added"}
          className="mb-0"
        />
        <CardElement
            onChange={handleCardChange}
            options={{
                style: {
                    base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#9e2146',
                    },
                },
            }}
        />*/}
      </Col>
    </Row>
  </DashboardCardWithSave>

}

LeadSettings.propTypes = {
  inTraffic: PropTypes.bool,
  handleSave: PropTypes.func,
  saveCard: PropTypes.func,
  professionalSettings: PropTypes.object
}
