import React, { Component } from 'react';
import _ from 'underscore';
import Question from "./Question";
import Options from "./Options";
import Actions from "./Actions";
import Card from "../Card"
import styles from './Steps.module.scss';

class Steps extends Component {
  state = {
    currentStep: this.props.currentStep || 1
  };

  constructor(props) {
    super(props);
    this.stepsRef = React.createRef();
  }

  handleChange(val,key) {
    this.props.handleChange && this.props.handleChange(val,key);
  }

  handleNext(val) {
    (val || _.isBoolean(val)) && this.handleChange(val);
    setTimeout(() => this.props.handleNext && this.props.handleNext(val), 100);
  }

  render() {
    const { steps, values, stepId, inTraffic, handleBack } = this.props;
    const { currentStep, flex } = this.state;
    const step = steps["step-"+currentStep];

    return (
      <Card className={styles.steps}>
        <Question question={step.question} />
        <Options
          step={step}
          selectedValue={values[stepId] && values[stepId].value}
          currentStep={currentStep}
          handleChange={this.handleChange.bind(this)}
          handleNext={this.handleNext.bind(this)}
        />
        <Actions
          handleBack={handleBack}
          inTraffic={inTraffic}
          type={step.type}
          totalSteps={Object.keys(steps).length}
          currentStep={currentStep}
          step={step}
          selectedValue={values[stepId] && values[stepId].value}
          handleNext={this.handleNext.bind(this)}
        />
    </Card>
    );
  }
}

export default Steps;
