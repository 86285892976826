import React from 'react';
import { Toast } from "react-bootstrap";

const Toasts = ({ messages }) => {
    return (
        <div
            style={{
                position: 'fixed',
                top: 20,
                right: 20,
            }}
        >
            {
                messages.map(message => <Toast>
                        <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                            <strong className="mr-auto">Hyponia</strong>
                            <small>just now</small>
                        </Toast.Header>
                        <Toast.Body>{ message.message }</Toast.Body>
                    </Toast>
                )
            }
        </div>
    );
};

export default Toasts;