import { call, put, delay } from "redux-saga/effects";
import { Actions, ActionTypes } from "../actions";
import { getProClients } from "./networks";

export default [[ActionTypes.DO_GET_LEADS, doGetLeads]];

function* doGetLeads() {
  const response = yield call(getProClients, localStorage.getItem("guid"));

  yield put(Actions.doneGetLeads(response.data.clients));
}
