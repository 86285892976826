import { call, put, delay } from "redux-saga/effects";
import { Actions, ActionTypes } from "../actions";
import { getProClients } from "./networks";

export default [
  [ActionTypes.DO_GET_CLIENTS, doGetClients],
  [ActionTypes.DO_GET_CLIENT_TIMELINE, doGetClientTimeline],
  [ActionTypes.DO_CLOSE_CLIENT_TIMELINE, doCloseClientTimeline],
];

function* doGetClients() {
  const response = yield call(getProClients, localStorage.getItem("guid"));
  console.log(response.data.clients);

  yield put(Actions.doneGetClients(response.data.clients));
}

function* doGetClientTimeline(UserObjectId) {
  yield put(Actions.doingGetClientTimeline(UserObjectId.payload));
  yield put(Actions.doneGetClientTimeline());
}
function* doCloseClientTimeline() {
  yield put(Actions.doneCloseClientTimeline());
}
