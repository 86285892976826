import React, { Component } from "react";
import Timeline from "../components/Timeline";
import { Actions } from "../actions";
import { connect } from "react-redux";
import _ from "underscore";

class TimelineContainer extends Component {
  componentWillMount() {
    this.props.getTimelines();
  }

  handleStepSelect(stepNumber) {
    this.props.getStepInfo({
      TimelineId: this.props.selectedTimelineId,
      StepId: stepNumber,
    });
  }

  handleNewNote(note) {
    this.props.doSaveNote(note);
  }

  handleSaveDetailFields(fields) {
    this.props.doSaveDetailFields(fields);
  }

  handleHire(categoryId, professionalId) {
    this.props.doHireProfessional({
      TimelineId: this.props.selectedTimelineId,
      CategoryId: categoryId,
      HiredProfessionalId: professionalId,
    });
  }

  handleSubmitExternalProfessional(data) {
    const payload = {
      ...data,
      TimelineId: this.props.selectedTimelineId,
    };

    this.props.doSaveExternalProfessional(payload);
  }

  render() {
    return (
      <Timeline
        handleStepSelect={this.handleStepSelect.bind(this)}
        markStepCompleted={this.props.doMarkStepCompleted}
        handleHire={this.handleHire.bind(this)}
        handleSubmitExternalProfessional={this.handleSubmitExternalProfessional.bind(
          this
        )}
        handleSaveDetailFields={this.handleSaveDetailFields.bind(this)}
        handleNewNote={this.handleNewNote.bind(this)}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.sidebar,
    ...state.timeline,
    ...state.clients,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, Actions)(TimelineContainer);
