import { ActionTypes } from '../actions';
import _ from 'underscore';

const defaultState = {
    messages: []
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.DOING_SHOW_ERROR_MESSAGE:
                return {
                    ...state,
                    messages: [
                        ...state.messages,
                        {
                            id: action.payload.id,
                            type: 'error',
                            message: action.payload.message
                        }
                    ]
                };

            case ActionTypes.DONE_SHOW_ERROR_MESSAGE:
                return {
                    ...state,
                    messages: _.filter(state.messages, message => message.id !== action.payload)
                };

            case ActionTypes.DONE_SHOW_MESSAGE:
                return {
                    ...state,
                    messages: []
                };

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in timeline reducer: ${ error.message || error.code || error }`, error);
    }
};
