import { ActionTypes } from '../actions';

const defaultState = {
    isLoading: false,
    inTraffic: false,
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADING_DOCUMENTS:
            case ActionTypes.LOADING_DOCUMENT_TYPES:
                return {
                    ...state,
                    inTraffic: true,
                    isLoading: true
                };

            case ActionTypes.LOADED_DOCUMENTS:
                return {
                    ...state,
                    inTraffic: false,
                    isLoading: false,
                    isDocumentsLoaded: true,
                    documents: action.payload.Documents || []
                };

            case ActionTypes.LOADED_DOCUMENT_TYPES:
                return {
                    ...state,
                    inTraffic: false,
                    isLoading: false,
                    isDocumentTypesLoaded: true,
                    documentTypes: action.payload
                };

            case ActionTypes.DOING_UPLOAD_DOCUMENT:
                return {
                    ...state,
                    isUploadingDocument: true
                };

            case ActionTypes.DONE_UPLOAD_DOCUMENT:
                return {
                    ...state,
                    isUploadingDocument: false
                };

            case ActionTypes.DOING_DELETE_DOCUMENT:
                return {
                    ...state,
                    deleteId: action.payload.DocumentId,
                };
            case ActionTypes.DONE_DELETE_DOCUMENT:
                return {
                    ...state,
                    deleteId: null,
                    documents: {
                        ...state.documents,
                        [action.payload.DocumentCategory]: state.documents[action.payload.DocumentCategory].filter(doc => doc.Id !== action.payload.DocumentId)
                    }
                };

            case ActionTypes.DOING_UPDATE_DOCUMENT:
                return {
                    ...state,
                    updateId: action.payload.DocumentId,
                };

            case ActionTypes.DONE_UPDATE_DOCUMENT:
                return {
                    ...state,
                    updateId: null
                };

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in documentsState reducer: ${ error.message || error.code || error }`, error);
    }
};
