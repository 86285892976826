import React from 'react';
import {FormControl, InputGroup} from "react-bootstrap";
import classNames from "classnames";
import {numberWithCommas} from "../../index";

const Number = ({ handleChange = () => {}, condition, value, editable, append, prepend }) => {
    const [val, setVal] = React.useState(value || "");

    const _handleChange = v => {
        let re = condition === "int" ? /^[0-9\,\b]+$/ : /^[0-9\,]+(\.([0-9]{1,2})?)?$/;

        v = prepend === "$" ? numberWithCommas(v) : v;
        if (append === "%" && v >= 100) {
            return;
        }

        if (v === '' || re.test(v)) {
            handleChange(v);
            setVal(v);
        } else {
            setVal(val);
        }
    };

    return (
        <React.Fragment>
            {
                prepend && <InputGroup.Prepend className={classNames({ readonly: !editable })}>
                    <InputGroup.Text id="basic-addon1">{ prepend }</InputGroup.Text>
                </InputGroup.Prepend>
            }
            <FormControl
                className={"form-control"}
                readOnly={!editable}
                defaultValue={val}
                value={val}
                onChange={e => _handleChange(e.target.value)}
            />
            {
                append && <InputGroup.Append className={classNames({ readonly: !editable })}>
                    <InputGroup.Text id="basic-addon1">{ append }</InputGroup.Text>
                </InputGroup.Append>
            }
        </React.Fragment>
    );
};

export default Number;