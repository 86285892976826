import { ActionTypes } from '../actions';
import onboardingSteps from './onboarding-steps.json';
import queryParams from "queryparams";

const defaultState = {
    isLoading: true,
    inTraffic: false,
    steps: onboardingSteps
};

export default (state = defaultState, action) => {
    state.guid = queryParams()['guid'] || localStorage.getItem("guid");
    localStorage.setItem('guid', state.guid);

    try {
        switch(action.type) {
            case ActionTypes.LOADING_ANSWERED_QUESTIONS:
                return {
                    ...state,
                    isLoading: true
                };

            case ActionTypes.LOADED_ANSWERED_QUESTIONS:
                return {
                    ...state,
                    isLoading: false,
                    completedSteps: action.payload
                };

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in dashboardState reducer: ${ error.message || error.code || error }`, error);
    }
};
