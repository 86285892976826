import React from "react";
import classNames from "classnames";

import Button from "../../components/Button"
import Layout from "../../components/Layout"
import DashboardCard from "../../components/DashboardCard"

import styles from './ContactUs.module.scss'

const Card = (props) => (
  <DashboardCard
    xs={12}
    lg={4}
    className={classNames(
      styles.card,
      "d-flex flex-column align-items-center justify-content-center"
    )}
  >
    <div className="d-flex align-items-center justify-content-center mb-4">
      <i className={`icon icon-${props.icon}`}/>
    </div>
    <h5>{props.title}</h5>
    {props.children}
  </DashboardCard>
)

export default function ContactUs() {

  return <Layout title="Contact us">
    <Card title="Live chat" icon="message">
      <Button
        onClick={() => window.fcWidget.open()}
        className={styles.button}
      >
        Send message
      </Button>
    </Card>
    <Card title="Email us" icon="at-sign">
      <a href="mailto:info@hyponia.com">info@hyponia.com</a>
    </Card>
    <Card title="Call us" icon="phone">
      <a href="tel:551-998-6154">(551) 998-6154</a>
    </Card>
  </Layout>

}
