import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import PrivateRoute from "./utils/PrivateRoute";
import LeadsContainer from "./containers/Leads";
import ReviewsContainer from "./containers/Reviews";
import ProfileContainer from "./containers/Profile";
import ProfileHomebuyerContainer from "./containers/ProfileHomebuyer";
import ClientsContainer from "./containers/Clients";
import MessagesContainer from "./containers/Messages";
import SettingsContainer from "./containers/Settings";
import DashboardContainer from "./containers/Dashboard";
import OnboardingContainer from "./containers/Onboarding";
import AuthContainer from "./containers/Auth";
import Dashboard from "./components/Dashboard";
import NewSignup from "./pages/NewSignup";
import ContactUs from "./pages/ContactUs/";

export default (
  <div>
    <ScrollToTop>
      <Switch>
        <PrivateRoute path="/clients" component={ClientsContainer} />
        <PrivateRoute path="/leads" component={LeadsContainer} />
        <PrivateRoute path="/reviews" component={ReviewsContainer} />
        <PrivateRoute path="/messages" component={MessagesContainer} />
        <PrivateRoute path="/profile-homebuyer" component={ProfileHomebuyerContainer} />
        <PrivateRoute path="/settings" component={SettingsContainer} />
        <Route path="/new-signup" component={NewSignup} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/login" component={AuthContainer} />
        <Route path="/signup" component={AuthContainer} />
        <Route path="/onboarding" component={OnboardingContainer} />
        <Route path="/dash">
          <Redirect to="/profile" />
        </Route>
        <PrivateRoute path="/profile" component={ProfileContainer} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </ScrollToTop>
  </div>
);
