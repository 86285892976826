import {call, put, select} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {uploadDocument, loadDocuments, loadDocumentTypes, updateDocument, deleteDocument} from './networks';

export default [
    [ActionTypes.DO_UPLOAD_DOCUMENT, doUploadDocument],
    [ActionTypes.GET_DOCUMENTS, getDocuments],
    [ActionTypes.GET_DOCUMENT_TYPES, getDocumentTypes],
    [ActionTypes.DO_UPDATE_DOCUMENT, doUpdateDocument],
    [ActionTypes.DO_DELETE_DOCUMENT, doDeleteDocument],
];

const getTimelineState = (state) => state.timeline;

function * doUploadDocument ({ payload }) {
    yield put(Actions.doingUploadDocument());
    yield put(Actions.doShowMessage({message: "Uploading Document..."}));
    const response = yield call(uploadDocument, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.doneUploadDocument(response.data));
        yield put(Actions.doneShowMessage());
        yield put(Actions.getDocuments());
    }
}

function * getDocuments () {
    yield put(Actions.loadingDocuments());
    let timeline = yield select(getTimelineState);
    const payload = {
        TimelineId: timeline.selectedTimelineId
    };
    const response = yield call(loadDocuments, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedDocuments(response.data));
    }
}

function * getDocumentTypes ({ payload }) {
    yield put(Actions.loadingDocumentTypes());
    const response = yield call(loadDocumentTypes, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedDocumentTypes(response.data));
    }
}

function * doUpdateDocument ({ payload }) {
    yield put(Actions.doingUpdateDocument(payload));
    const response = yield call(updateDocument, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.doneUpdateDocument(response.data));
        yield put(Actions.getDocuments());
    }
}

function * doDeleteDocument ({ payload }) {
    yield put(Actions.doingDeleteDocument(payload));
    const response = yield call(deleteDocument, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.doneDeleteDocument(payload));
        yield put(Actions.getDocuments());
    }
}