import React from 'react';
import { Form } from "react-bootstrap";
import _ from "underscore"
import PropTypes from 'prop-types'

import DashboardCardWithSave from "../../components/DashboardCardWithSave"


const ProfileVisibility = ({ inTraffic, handleSave, professionalSettings }) => {

  const {
    Enabled
  } = professionalSettings

  const proSettings = {
    Enabled
  }

  const [values, setValues] = React.useState({
    ...proSettings
  });

  React.useEffect(()=>{
    setValues({
      ...proSettings
    })
  },[professionalSettings])

  const _handleSave = () => {
    handleSave({
      ...professionalSettings,
      ...values
    });
  };

  const _setValues = newVals => {
    setValues({
      ...values,
      ...newVals
    })
  };

  return (
    <DashboardCardWithSave
      handleSave={_handleSave}
      isSaving={inTraffic}
      title="Profile visibility"
      xs={12}
      hideSave={_.isEqual(values,proSettings)}
    >
      <Form.Group className="m-0">
        <Form.Check
          checked={values.Enabled}
          type="switch"
          id="custom-switch"
          label={values.Enabled ? "Visible" : "Hidden"}
          onChange={e => _setValues({ Enabled: e.target.checked})}
        />
      </Form.Group>
    </DashboardCardWithSave>
  );
};

export default ProfileVisibility;

ProfileVisibility.propTypes = {
  inTraffic: PropTypes.bool,
  handleSave: PropTypes.func,
  professionalSettings: PropTypes.object
}
