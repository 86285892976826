import React from 'react';
import {
  Col
} from "react-bootstrap";
import Layout from "../../components/Layout"
import ProfessionalDetail from "../../components/ProfessionalDetail";
import Link from "../../utils/Link";

const ProfessionalProfile = ({
  location,
  professionalDetails,
  professionalsCategories,
  saveProfessional,
  isProfessionalDetailsSaving,
  doSaveProfileImage
}) => {

  return <Layout title="Public profile">

    <Col xs={12} className="text-right mb-2">
      <Link to="/profile-homebuyer">
        View as home buyer
      </Link>
    </Col>
    <ProfessionalDetail
      handleSave={saveProfessional}
      doSaveProfileImage={doSaveProfileImage}
      professional={professionalDetails}
      categories={professionalsCategories}
      isSaving={isProfessionalDetailsSaving}
   />
  </Layout>


};

export default ProfessionalProfile;
