import { call, put, select } from "redux-saga/effects";
import { Actions, ActionTypes } from "../actions";
import _ from "underscore";
import queryParams from "queryparams";
import {
  userInfoByObjectId,
  saveOnboardingStep,
  saveUserInfo,
  answeredQuestions,
  login,
} from "./networks";
import history from "../history";
import { stringifyAddress } from "../utils";

export default [
  [ActionTypes.GET_USER_INFO_BY_OBJECT_ID, getUserInfoByObjectId],
  [ActionTypes.DO_SAVE_ONBOARDING_STEP, doSaveOnboardingStep],
  [ActionTypes.DO_SAVE_USER_INFO, doSaveUserInfo],
  [ActionTypes.DO_ONBOARDING_LOGIN, doOnboardingLogin],

];

const getProfessionalState = (state) => state.professionals;
const getOnboardingState = (state) => state.onboarding;

function* getUserInfoByObjectId({ payload }) {
  yield put(Actions.loadingUserInfoByObjectId());

  const response = yield call(userInfoByObjectId, [payload]);

  if (!response.successful) {
    console.log("Error", response.code);
  } else {
    yield put(Actions.loadedUserInfoByObjectId(response.data));
  }
}

function* doSaveOnboardingStep() {
  yield put(Actions.doingSaveOnboardingStep());

  let professionals = yield select(getProfessionalState);
  let onboarding = yield select(getOnboardingState);
  var payload = _.values(onboarding.values).reduce(
    (pObj, step) => ({ ...pObj, [step.key]: step.value }),
    {}
  );

  payload = {
    ...payload,
    ...payload.BusinessDetailsOne,
    ...payload.BusinessDetailsTwo
  }

  delete payload.BusinessDetailsOne
  delete payload.BusinessDetailsTwo

  let proDetails = {
    ...professionals.professionalDetails,
  };

  if(payload.ServicesCounties){
    proDetails.ServiceAreas["New York"] =
      payload.ServicesCounties;
  }
  delete payload.ServicesCounties;

  const password = proDetails.Password;
  delete proDetails.Password;
  delete proDetails.ServicesCities;
  delete proDetails.ServicesCounties;

  payload = {
    ...proDetails,
    ...payload,
  };

  const response = yield call(saveOnboardingStep, payload);

  if (!response.successful) {
    console.log("Error", response.code);
  } else {
    if (onboarding.currentStep === Object.keys(onboarding.steps).length) {
      //console.log(payload.Password)
      //console.log(payload.Email)

      window.location.href = '/profile';

      //yield put( Actions.doOnboardingLogin({Email:payload.Email, Password:password }))

    }else{
      yield put(Actions.doneSaveOnboardingStep({ ...payload, ...response.data }));

    }
  }
}

function* doOnboardingLogin({ payload }) {
  const response = yield call(login, payload);

  localStorage.setItem("access_token", response.data.Access);
  localStorage.setItem("refresh_token", response.data.Refresh);
  localStorage.setItem("guid", response.data.Guid);
  localStorage.setItem("email", payload.Email);
return;
  setTimeout(() => {
    window.location.href = '/profile';
  }, 2000);
}

function* doSaveUserInfo({ payload }) {
  yield put(Actions.doingSaveUserInfo());
  const response = yield call(saveUserInfo, payload);
  if (!response.successful) {
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.doneSaveUserInfo({ ...response.data }));
  }
}
