import React from 'react';
import {isMobile} from "react-device-detect";
import Datetime from "react-datetime";
import {FormControl} from "react-bootstrap";
import moment from "moment";
import './index.scss';

const DateTime = ({ handleChange = () => {}, value, selectedValue, editable }) => {
    return (
        <React.Fragment>
            {
                isMobile
                    ? <FormControl type="date" placeholder={"MM/DD/YYYY"} onChange={e => handleChange(e.target.value)} value={moment(value).format ? moment(value).format("YYYY-MM-DD") : value} readOnly={!editable}/>
                    : <Datetime inputProps={{placeholder: "MM/DD/YYYY", readOnly: !editable, className:"form-control"}}
                                onChange={moment => handleChange(moment.format ? moment.format("MM/DD/YYYY") : moment)}
                                closeOnSelect
                                value={moment(value).format ? moment(value).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY")}
                                timeFormat={false} />
            }
        </React.Fragment>
    );
};

export default DateTime;