import React from "react";
const DetailContent = ({ Info, StepNumber }) => {
  console.log(Info[StepNumber]);

  const renderParagraph = (paragraph) => {
    switch (paragraph.type) {
      case "paragraph":
        return <p>{paragraph.text}</p>;
      case "header":
        return (
          <p className={"heading"}>
            <strong>{paragraph.text}</strong>
          </p>
        );
      case "dashList":
        return (
          <div style={{ marginLeft: 25 }}>
            <p className={"heading"}>
              -<strong style={{ marginLeft: 20 }}> {paragraph.header} </strong>{" "}
              <span style={{ margin: 5 }}>-</span>
              <span
                style={{
                  fontSize: 16,
                  color: "#5E6069",
                  marginBottom: 8,
                  fontWeight: 400,
                }}
              >
                {paragraph.text}
              </span>
            </p>
          </div>
        );
      case "listParagraph":
        return <p style={{ marginLeft: 20 }}>{paragraph.text}</p>;
      case "br":
        return <br />;
      case "dotsParagraph":
        return (
          <ul>
            {paragraph.texts.map((text) => (
              <li>
                <p> {text} </p>
              </li>
            ))}
          </ul>
        );
      case "marginParagraph":
        return <p style={{ marginLeft: 20 }}>{paragraph.text}</p>;

      default:
        break;
    }
  };
  return (
    <div className={"rui__timeline-tips"}>
      <React.Fragment>
        {Info[StepNumber].map((info) => renderParagraph(info))}
      </React.Fragment>
    </div>
  );
};

export default DetailContent;
