import React from "react";
import classNames from "classnames";

import styles from "./Reviews.module.scss";
import Review from "../Review";

export default function Reviews({reviews}) {
  return (
    <div className={styles.reviews}>
      <div className={classNames("row",styles.reviewsRow)}>
        {reviews.map((review, i) => (
          <Review key={i} score={review.stars/2} title={review.title} comment={review.content}/>
        ))}
      </div>
    </div>
  );
}
