import React from "react"
import { Row, Col } from "react-bootstrap";

import DashboardCardWithSave from "../../components/DashboardCardWithSave"
import FormControl from "../../components/FormControl"

export default function HomeBuyerCriteria({ }) {

  return <DashboardCardWithSave
    handleSave={() => { }}
    isSaving={false}
    title="Home buyer criteria"
    beta
    xs={12}
  >
    <Row>
      <Col lg="6" md="8">
        <FormControl
          type="checkboxes"
          options={[
            {
              label: "Accept all home buyers",
              checked: true
            }
          ]}
          onChange={() => { }}
          disabled
        />
        <FormControl
          label="Minimum home purchase price"
          type="prepend"
          prependText="$"
          value=""
          onChange={() => { }}
          disabled
        />
        <FormControl
          label="Minimum credit score"
          type="text"
          value=""
          onChange={() => { }}
          disabled
          className="mb-0"
        />
      </Col>
    </Row>
  </DashboardCardWithSave>
}
