import {call, put, select} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {answeredQuestions} from './networks';

export default [
    [ActionTypes.GET_ANSWERED_QUESTIONS, getAnsweredQuestions],
];

function * getAnsweredQuestions ({ payload }) {
    yield put(Actions.loadingAnsweredQuestions());
    const response = yield call(answeredQuestions, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedAnsweredQuestions(response.data));
    }
}
