import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Notes from "./Notes";
import TimelineSteps from "./TimelineSteps";
import Details from "./Details";
import Links from "./Links";
import Tools from "./Tools";
import _ from "underscore";
import "./index.scss";
import { roundByNum } from "../../utils";
import Loader from "../../utils/Loader";
import Documents from "./Documents";
import Content from "./Content";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import classnames from "classnames";
import Steps from "../Steps";

const Timeline = ({
  isTimelineOpen,
  timelines,
  stepInfo,
  stepProfessionals,
  isDefault,
  selectedStepId,
  selectedTimelineId,
  isTimelineLoading,
  isTimelineStepLoading,
  isTimelineProfessionalsLoading,
  handleStepSelect,
  handleNewNote,
  isSavingNote,
  handleHire,
  hiringProfessionalId,
  markStepCompleted,
  isMarkingStepCompleted,
  handleSaveDetailFields,
  isSavingDetailFields,
  handleSubmitExternalProfessional,
  doCloseClientTimeline,
  auth,
}) => {
  let [nav, setNav] = React.useState(0);
  let [direction, setDirection] = React.useState("forward");
  if (isTimelineLoading) {
    return <Loader />;
  }

  const selectedTimeline = _.findWhere(timelines, { Id: selectedTimelineId });
  const allSteps = selectedTimeline.Steps || selectedTimeline.DefaultSteps;
  const selectedStep =
    _.findWhere(allSteps, { Id: selectedStepId }) ||
    _.findWhere(allSteps, { StepNumber: selectedStepId });
  const estimatedTime = allSteps.reduce(
    (totalTime, step) => totalTime + step.DurationDays,
    0
  );
  const totalStepsCompleted = allSteps.reduce(
    (completed, step) => completed + (step.Completed ? 1 : 0),
    0
  );
  const progress = Math.round((totalStepsCompleted / allSteps.length) * 100);

  const _handleSelect = (...props) => {
    _go(1, "forward");
    handleStepSelect(...props);
  };

  const _go = (nav, direction) => {
    if (direction === "backward") {
      setDirection("backward");
    } else {
      setDirection("forward");
    }
    setNav(nav);
  };

  return (
    <div id={"rui__timeline-container"} className={"dashboard--gutters"}>
      <Container id={"rui__dashboard"}>
        <div>
          <Row className={"d-none d-md-flex"}>
            <TimelineSteps
              estimatedTime={estimatedTime}
              isLoading={isTimelineLoading}
              doCloseClientTimeline={doCloseClientTimeline}
              steps={allSteps}
              progress={progress}
              selectedStepId={selectedStepId}
              handleSelect={handleStepSelect}
              selectedTimelineId={selectedTimelineId}
              isOpen={isTimelineOpen}
            />
            <Content
              stepInfo={stepInfo}
              isTimelineOpen={isTimelineOpen}
              isTimelineStepLoading={isTimelineStepLoading}
              isTimelineProfessionalsLoading={isTimelineProfessionalsLoading}
              stepProfessionals={stepProfessionals}
              handleHire={handleHire}
              hiringProfessionalId={hiringProfessionalId}
              markStepCompleted={markStepCompleted}
              isMarkingStepCompleted={isMarkingStepCompleted}
              handleSaveDetailFields={handleSaveDetailFields}
              isSavingDetailFields={isSavingDetailFields}
              handleSubmitExternalProfessional={
                handleSubmitExternalProfessional
              }
              selectedStep={selectedStep}
              handleNewNote={handleNewNote}
              isGuest={auth.isGuest}
              isSavingNote={isSavingNote}
            />
          </Row>
          <Row className={"d-md-none"}>
            <SwitchTransition mode={"out-in"}>
              <CSSTransition
                key={nav}
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                classNames={classnames(
                  { "is-back": direction === "backward" },
                  "fade"
                )}
              >
                {nav === 0 ? (
                  <TimelineSteps
                    estimatedTime={estimatedTime}
                    isLoading={isTimelineLoading}
                    steps={allSteps}
                    progress={progress}
                    doCloseClientTimeline={doCloseClientTimeline}
                    selectedStepId={selectedStepId}
                    handleSelect={_handleSelect}
                    selectedTimelineId={selectedTimelineId}
                    isOpen={isTimelineOpen}
                  />
                ) : (
                  <Content
                    stepInfo={stepInfo}
                    isTimelineOpen={isTimelineOpen}
                    isTimelineStepLoading={isTimelineStepLoading}
                    isTimelineProfessionalsLoading={
                      isTimelineProfessionalsLoading
                    }
                    stepProfessionals={stepProfessionals}
                    handleHire={handleHire}
                    hiringProfessionalId={hiringProfessionalId}
                    markStepCompleted={markStepCompleted}
                    isMarkingStepCompleted={isMarkingStepCompleted}
                    handleSaveDetailFields={handleSaveDetailFields}
                    isSavingDetailFields={isSavingDetailFields}
                    handleSubmitExternalProfessional={
                      handleSubmitExternalProfessional
                    }
                    selectedStep={selectedStep}
                    handleNewNote={handleNewNote}
                    goBack={() => _go(0, "backward")}
                    goForward={() => _go(1, "forward")}
                    isGuest={auth.isGuest}
                    isSavingNote={isSavingNote}
                  />
                )}
              </CSSTransition>
            </SwitchTransition>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Timeline;
