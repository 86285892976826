import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import "./index.scss";

const ClientCard = ({ key, client }) => {
  return (
    <div key={key} id={"client-card"}>
      <Card>
        <Card.Body>
          <Row>
            <Col md={3} sm={12}>
              <p className="data-p firstname-text"> {client.FirstName}</p>
            </Col>
            <Col md={3} sm={12}>
              <p className="data-p  lastname-text"> {client.LastName}</p>
            </Col>
            <Col md={3} sm={12}>
              <p className="data-p email-text"> {client.Email}</p>
            </Col>
            <Col md={3} sm={12}>
              <p className="data-p phone-text"> {client.Phone}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ClientCard;
