import React, { Component } from "react";
import { Router } from "react-router-dom";
import routes from "../routes";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Actions } from "../actions";
import Toasts from "../components/Toasts";
import history from "../history";
import queryParams from "queryparams";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import classNames from "classnames";

ReactGA.initialize("UA-165836730-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class Website extends Component {
  componentWillMount() {
    history.listen(() => {
      this.props.getProfessionalDetails();
      this.props.getProfessionalsCategories();
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.props.doRefreshToken();
      this.props.setCurrentPath(window.location.pathname);
    });
  }

  render() {
    const { sidebar, onboarding } = queryParams(window.location.search);
    const shouldShowTimeline =
      ["/timeline", "/timeline/"].indexOf(window.location.pathname) !== -1;
    const oldExperience =
      ["/","/onboarding","/new-signup"].indexOf(window.location.pathname) !== -1;
    const shouldShowSidebar =
      sidebar === "open" ||
      [
        "/",
        "/onboarding",
        "/onboarding/",
        "/new-signup",
        "/login",
        "/login/",
        "/signup",
        "/signup/",
      ].indexOf(window.location.pathname) === -1;
    const { professionalDetails } = this.props.professionals;

    const handleLogout = () => {
        this.props.doLogout();
        window.location.replace(process.env.REACT_APP_WEBSITE_URL+"/logout")
    }

    return (
      <Router history={history}>
        {shouldShowSidebar && (
          <Sidebar
            currentPath={this.props.currentPath}
            isOpen={this.props.isSidebarOpen}
            auth={this.props.auth}
            handleLogout={handleLogout}
          />
        )}
        <Toasts messages={this.props.messages} />
        <Header
          minimal={!oldExperience}
          classes={classNames({ "push--top": !oldExperience })}
          logo={oldExperience}
          relative={oldExperience}
          shouldShowSidebar={shouldShowSidebar}
          shouldShowTimeline={shouldShowTimeline}
          toggleSidebar={this.props.toggleSidebar}
          toggleTimeline={this.props.toggleTimeline}
          isSidebarOpen={this.props.isSidebarOpen}
          isTimelineOpen={this.props.isTimelineOpen}
        />
        <div>{routes}</div>
      </Router>
    );
  }
}

const mapStateToProps = (props) => {
  return {
    ...props.sidebar,
    ...props.toasts,
    professionals: props.professionals,
    auth: props.auth,
  };
};

export default connect(mapStateToProps, Actions)(Website);
