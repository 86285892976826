import React from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Col } from 'react-bootstrap';
import NavLink from "./NavLink";
import classNames from 'classnames';
import DashboardOptions from "./DashboardOptions";
import AccountOptions from "./AccountOptions";
import "./index.scss";
import styles from "./Sidebar.module.scss"

const Sidebar = ({ isOpen, currentPath, auth, handleLogout }) => {
    return (
        <Navbar className={classNames({ 'is-open': isOpen })} bg="light" expand="lg">
            <Navbar.Brand  className={"rui__logo"}>
                <div  className={'logo-link nav-link mb-0'}>
                    <Col className="sidebar__icon-container">
                        <img className={"icon icon-logo"}/>
                    </Col>
                    <Col className={"sidebar__label-container"}>
                        Hyponia
                    </Col>
                </div>
                <p className={classNames("red",styles.beta)}>BETA</p>
            </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <DashboardOptions currentPath={currentPath} />
                    <AccountOptions currentPath={currentPath} auth={auth} handleLogout={handleLogout}/>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Sidebar;
