import React from 'react';
import { Button } from 'react-bootstrap';
import './index.scss';
import Link from '../../../utils/Link';

const NotReady = () => {
    return (
        <div className={"rui__dashboard-timeline centered"}>
            <img className={"icon icon-timeline"} alt=""/>
            <h5>Your timeline is not ready yet</h5>
            <p>Complete your profile and get the timeline ready. Timeline is your guide in a journey of buying your dream house or apartment!</p>
            <Link to={"/onboarding"}><Button>Complete Profile</Button></Link>
        </div>
    );
};

export default NotReady;