import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./index.scss";
import LeadsCard from "./LeadsCard";

const Leads = ({ leads }) => {
  return (
    <div>
      <div id={"professional-leads"} className={"dashboard--gutters"}>
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={9}>
              <h2 className={"remove--gutters mt-4"}> Leads </h2>
              <div className={"details-container"}>
                <div className="table-header">
                  <Row>
                    <Col sm={3}>
                      <p> First Name</p>
                    </Col>
                    <Col sm={3}>
                      <p>Last Name</p>
                    </Col>
                    <Col sm={3}>
                      <p>Email</p>
                    </Col>
                    <Col sm={3}>
                      <p>Phone</p>
                    </Col>
                  </Row>
                  <hr className="content-border"></hr>
                </div>
                {leads.map((client) => (
                  <LeadsCard key={client.UserObjectId} client={client} />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Leads;
