import React from 'react';

const Tips = ({ tips }) => {
    return (
        <div className={"rui__timeline-tips"}>
            {
                tips.map(tip => <React.Fragment>
                    <p className={"heading"}><strong>{ tip.Title }</strong></p>
                    <p>{ tip.Text }</p>
                </React.Fragment>)
            }
        </div>
    );
};

export default Tips;