import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ProfessionalDetail from "../../components/ProfessionalDetail";
import './index.scss';

const ProfessionalDashboard = (props) => {
    return <div>Dashboard</div>
};

export default ProfessionalDashboard;
