import React, {Component} from 'react';
import Onboarding from '../pages/Onboarding';
import queryParams from 'queryparams';
import { Redirect } from 'react-router-dom';
import { Actions } from "../actions";
import { connect } from "react-redux";
import _ from "underscore";
import Loader from "../utils/Loader";

class OnboardingContainer extends Component {
    state = { stepSelected: false };

    componentWillMount () {
        if (this.props.guid) {
            this.props.getProfessionalDetails({
                ProObjectId: this.props.guid
            })
        }
    }

    componentDidMount() {
        // this.props.doLogout();
    }

    loginMessage () {
        return <div id={"rui__dashboard-container"}>
            <div className={"centered onboarding-complete"}>
                <p>You are not logged in. Please contact administrator</p>
            </div>
        </div>;
    }

    render() {
        const { professionals, isLoading, guid } = this.props;

        if (!guid) {
            return <Redirect to={{ pathname: "/login" }}></Redirect>
        }

        if (isLoading || professionals.isProfessionalDetailsLoading) {
            return <Loader></Loader>
        }

        return <Onboarding { ...this.props }/>;
    }
}

const mapStateToProps = state => {
    return {
        ...state.onboarding,
        professionals: state.professionals
    }
};

export default connect(mapStateToProps, Actions)(OnboardingContainer);
