import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./index.scss";
import classNames from "classnames";

const ProfessionalDetail = ({
  professional,
  hired,
  handleHire,
  isHiring,
  icon,
}) => {
  return (
    <Row className={"rui__professional-detail"}>
      {icon ? (
        <div className={"icon-container"}>
          <img className={`icon icon-${icon}`} />
        </div>
      ) : (
        <img src="/images/user.svg" />
      )}
      <Col md={10} className={"professional-detail--content"}>
        <p className={"professional-detail--name"}>
          {professional.FirstName} {professional.LastName}
        </p>
        <p className={"professional-detail--company"}>
          <small>{professional.CompanyName}</small>
        </p>
        <p className={"professional-detail--location"}>
          <img className={"icon icon-pin"} alt="" />
          <small>{professional.Address}</small>
        </p>
        <Row className={"flexbox"}>
          <Col md={12} className={classNames({ blurred: false })}>
            <p className={"professional-detail--email"}>
              <img className={"icon icon-mail"} alt="" />
              <small>{professional.Email}</small>
            </p>
            <p className={"professional-detail--phone"}>
              <img className={"icon icon-phone"} alt="" />
              <small>{professional.Phone}</small>
            </p>
          </Col>
        </Row>
      </Col>
      {hired ? (
        <p className={"hired"}>
          <img className={"icon icon-rounded-check mr-1"} alt="" />{" "}
        </p>
      ) : (
        <Button
          className={"hire-btn"}
          onClick={() => handleHire(professional.Id)}
        >
          {isHiring ? (
            <img
              src="/images/loading.gif"
              className={"loader"}
              alt="Processing..."
            />
          ) : (
            "Hire"
          )}
        </Button>
      )}
    </Row>
  );
};

export default ProfessionalDetail;
