/**
 * Aggregator of reducers, sagas to build a store for the app
 * returns a store which is passed down to the props
 *
 **/
import { applyMiddleware, createStore, compose } from 'redux';
import allReducers from './reducers';
import { sagaMiddleware, sagas } from './sagas';
import queryParams from 'queryparams';
import history from "./history";


export default (initialState) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


  const guid = queryParams()['guid'];
  if (guid) {
    localStorage.setItem("guid", guid);
  }

  const accessToken = queryParams()['token'];
  if (accessToken) {
    localStorage.setItem("access_token", accessToken);
  }
  history.replace(history.location.pathname);

    const store = createStore(allReducers, initialState, composeEnhancers(
      applyMiddleware(sagaMiddleware)
    ))

  // Start all sagas.
  for (let saga of sagas) {
    sagaMiddleware.run(saga);
  }

  return store;
}
