import {call, put, delay} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {login, refreshToken} from './networks';

export default [
    [ActionTypes.DO_LOGIN, doLogin],
    [ActionTypes.DO_REFRESH_TOKEN, doRefreshToken],
    [ActionTypes.DO_LOGOUT, doLogout],
];

function * doLogin ({ payload }) {
    yield put(Actions.doingLogin());

    const response = yield call(login, payload);

    if (!response.successful) {
        console.log('Error', response.code);
        yield put(Actions.errorLogin());
        yield put(Actions.doShowErrorMessage({
            message: response.data.message
        }))
    } else {
        localStorage.setItem("access_token", response.data.Access);
        localStorage.setItem("refresh_token", response.data.Refresh);
        localStorage.setItem("guid", response.data.Guid);
        localStorage.setItem("email", response.data.Email);
        yield put(Actions.doneLogin(response.data));
    }
}

function * doRefreshToken () {
    yield put(Actions.doingRefreshToken());

    const payload = {
        Email: localStorage.getItem("email"),
        Access: localStorage.getItem("access_token"),
        Refresh: localStorage.getItem("refresh_token")
    };

    const response = yield call(refreshToken, payload);

    if (!response.successful) {
        console.log('Error', response.code);
        yield put(Actions.errorRefreshToken());
    } else {
        yield put(Actions.doneRefreshToken(response.data));
    }
}

function *  doLogout () {
    yield localStorage.clear()
    yield delay(1200);
    window.location = process.env.REACT_APP_WEBSITE_URL+"/logout"
}