import React from 'react';
import { Form, Dropdown, InputGroup } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Datetime from "react-datetime";
import MultipleValueTextInput from "react-multivalue-text-input";

import {
  stringifyAddress
} from "../../utils";

import styles from './FormControl.module.scss'

export default function HyponiaFormControl({
  controlId,
  className,
  label,
  type,
  value,
  disabled,
  onChange,
  onSelect,
  placeholder,
  options,
  name,
  prependText,
  ...other
}) {

  let content = null
  switch (type) {

    case "multitext":
      content = <MultipleValueTextInput
        className={"form-control mt-2"}
        onItemAdded={(item,allItems) => onChange(allItems)}
        onItemDeleted={(item,allItems) => onChange(allItems)}
        name="item-input"
        values={value}
      />

      break;

    case "datetime":
      content = <Datetime
        label={label}
        dateFormat="YYYY-MM"
        inputProps={{ placeholder: "YYYY-MM" }}
        onChange={onChange}
        closeOnSelect
        value={value}
        timeFormat={false}
      />
      break;
    case "address":

      if (!disabled) {
        content = <PlacesAutocomplete
          value={stringifyAddress(value)}
          onChange={onChange}
          onSelect={(address) => {
            geocodeByAddress(address).then(onSelect);
          }}
          searchOptions={{
            componentRestrictions: { country: ["us"] },
            types: ["geocode"],
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (<>
            <Form.Control
              type="text"
              value={value}
              {...getInputProps({
                placeholder: placeholder || "Start typing...",
                className: "location-search-input",
              })}
            />
            <div className="position-relative">
              <Dropdown.Menu
                className={styles.dropdown}
                show={loading || suggestions.length}
              >
                {loading && (
                  <Dropdown.Item>Loading...</Dropdown.Item>
                )}
                {suggestions.map((suggestion, i) => {
                  const className = classNames(suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item",
                    styles.dropdownItem
                  );
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                    }
                    : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                    };
                  return (
                    <Dropdown.Item
                      href="#/action-1"
                      key={i}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      {suggestion.description}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </div>
          </>)}
        </PlacesAutocomplete>
      } else {
        content = <Form.Control
          type="text"
          value={value}
          disabled={disabled}
          placeholder={placeholder || "Start typing..."}
        />
      }
      break;
    case "info":
      content = <p className={classNames(styles.info, "text-dark mb-0")}>{value}</p>
      break;
    case "radios":
      content = <>
        {
          options.map((o, idx) => <Form.Check
            key={idx}
            type="radio"
            label={o.label}
            name={name}
            onChange={onChange}
            checked={o.checked || false}
          />)
        }
      </>
      break;
    case "checkboxes":
      content = <>
        {
          options.map((o, idx) => <Form.Check
            key={idx}
            type="checkbox"
            label={o.label}
            onChange={onChange}
            checked={o.checked || false}
            name={o.label}
            disabled={disabled}
          />)
        }
      </>
      break;
    case "prepend":
      content = <InputGroup>
        {
          prependText &&
          <InputGroup.Prepend>
            <InputGroup.Text>{prependText}</InputGroup.Text>
          </InputGroup.Prepend>
        }
        <Form.Control
          type="text"
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </InputGroup>
      break;
    case "select":
      content = <Form.Control
        as="select"
        onChange={onChange}
      >
        <option value="0" selected disabled>
          {placeholder}
        </option>
        {options.map(option => (
          <option
            selected={option.selected}
            value={option.value}
            key={option.value}
          >
            {option.label}
          </option>
        ))}
      </Form.Control>
      break;
    case "textarea":
      content = <Form.Control
        as="textarea"
        rows="3"
        value={value}
        onChange={onChange}
        className={styles.textarea}
      />
      break;
    default:
      content = <Form.Control
        type={type}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
      />
  }
  return <Form.Group controlId={controlId || ""} className={classNames("mb-2", className)} {...other}>
    {label && <Form.Label className={classNames(styles.label, "mb-0")}>{label}</Form.Label>}
    {content}
  </Form.Group>
}

HyponiaFormControl.propTypes = {
  controlId: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  prependtext: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    selected: PropTypes.bool,
    value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
    label: PropTypes.string
  }))
}
