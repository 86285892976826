import React from 'react';
import classNames from 'classnames';
import {FormControl, InputGroup} from "react-bootstrap";

const String = ({ handleChange = () => {}, value, editable, append, prepend }) => {
    return (
        <React.Fragment>
            {
                prepend && <InputGroup.Prepend className={classNames({ readonly: !editable })}>
                    <InputGroup.Text id="basic-addon1">{ prepend }</InputGroup.Text>
                </InputGroup.Prepend>
            }
            <FormControl
                className={"form-control"}
                readOnly={!editable}
                defaultValue={value}
                onChange={e => handleChange(e.target.value)}
            />
            {
                append && <InputGroup.Append className={classNames({ readonly: !editable })}>
                    <InputGroup.Text id="basic-addon1">{ append }</InputGroup.Text>
                </InputGroup.Append>
            }
        </React.Fragment>
    );
};

export default String;