export default {
  1: [
    {
      type: "paragraph",
      text:
        "Before you start looking for a home, you will need to know how much you can actually spend. Lenders generally recommend that people look for homes that cost no more than three to five times their annual household income. The best way to do that is to get prequalified or pre-approved for a mortgage.",
    },

    {
      type: "paragraph",
      text:
        "To get prequalified, you just need to provide some financial information to your mortgage banker, such as your income and the amount of savings and investments you have. Your lender will review this information and tell you how much they can lend you. This will tell you the price range of the homes you should be looking at.",
    },

    {
      type: "paragraph",
      text:
        "Later, you can get pre-approved for credit, which involves providing your financial documents (W-2 statements, paycheck stubs, bank account statements, etc.) so your lender can verify your financial status and credit. A pre-approval is generally necessary when making an offer to purchase a property.",
    },

    {
      type: "br",
    },

    {
      type: "header",
      text: "Requirements for pre-approval:",
    },

    {
      type: "dashList",
      header: "Proof of income",
      text:
        "Buyers generally must produce W-2 wage statements from the past two years, recent pay stubs that show income as well as year-to-date income, proof of any additional income such as alimony or bonuses, and the two most recent years' tax returns. If self-employed you will need the last 2 year’s tax returns.",
    },

    {
      type: "dashList",
      header: "Proof of assets",
      text:
        "The borrower needs bank statements and investment account statements to prove that they have funds for the down payment and closing costs, as well as cash reserves.",
    },

    {
      type: "listParagraph",
      text:
        "The down payment, expressed as a percentage of the selling price, varies by loan type. Most loans come with a requirement that the buyer purchase private mortgage insurance (PMI) or pay a mortgage insurance premium or a funding fee unless they are putting down at least 20% of the purchase price.",
    },
    {
      type: "listParagraph",
      text:
        "In addition to the down payment, pre-approval is also based on the buyer's FICO credit score, debt-to-income ratio (DTI), and other factors, depending on the type of loan.",
    },

    {
      type: "dashList",
      header: "Credit History",
      text:
        "Most lenders require a FICO score of 620 or higher to approve a conventional loan, but you may still qualify Federal Housing Administration loan with a score as low as 580. Lenders typically reserve the lowest interest rates for customers with a credit score of 760 or higher. FHA guidelines allow approved borrowers with a score of 580 or higher to pay as little as 3.5% down. Those with lower scores must make a larger down payment. Lenders will often work with borrowers with a low or moderately low credit score and suggest ways to improve their score.",
    },

    {
      type: "dashList",
      header: "Employment Verification",
      text:
        "Lenders want to make sure they lend only to borrowers with stable employment. A lender will not only want to see a buyer's pay stubs but also will likely call the employer to verify employment and salary. A lender may want to contact the previous employer if a buyer recently changed jobs.",
    },

    {
      type: "paragraph",
      text:
        "Self-employed buyers will need to provide significant additional paperwork concerning their business and income.",
    },
    {
      type: "paragraph",
      text:
        "Consulting with a lender before the home-buying process can save a lot of heartache later. Gather paperwork before the pre-approval appointment, and definitely before you go house hunting.",
    },
  ],
  2: [
    {
      type: "paragraph",
      text:
        "Start touring homes in your price range. You can find listings on sites like Zillow and Trulia. It might be helpful to take notes on all the homes you visit. You will see a lot of houses! It can be hard to remember everything about them, so you might want to take pictures or video to help you remember each home. Make sure to check out the little details of each house. For example:",
    },
    {
      type: "dotsParagraph",
      texts: [
        "Test the plumbing by running the shower to see how strong the water pressure is and how long it takes to get hot water",
        "Try the electrical system by turning switches on and off",
        "Open and close the windows and doors to see if they work properly",
      ],
    },
    {
      type: "paragraph",
      text:
        "It’s also important to evaluate the neighborhood and make a note of things such as:",
    },
    {
      type: "dotsParagraph",
      texts: [
        "Are the other homes on the block well maintained?",
        "How much traffic does the street get?",
        "Is there enough street parking for your family and visitors?",
        "Is it conveniently located near places of interest to you: schools, shopping centers, restaurants, parks, and public transportation?",
      ],
    },

    {
      type: "paragraph",
      text:
        "Take as much time as you need to find the right home. Once you and the seller have reached agreement on a price, the house will go into escrow, which is the period of time it takes to complete all of the remaining steps in the home buying process.",
    },

    {
      type: "br",
    },

    {
      type: "paragraph",
      text: "Using a real estate agent",
    },

    {
      type: "paragraph",
      text:
        "Real estate agents can be useful partners when you’re buying or selling a home. Real estate agents can provide you with helpful information on homes and neighborhoods that isn’t obvious to a first time home buyer. Their knowledge of the home buying process, negotiating skills, and familiarity with the area you want to live in can be extremely valuable.",
    },
  ],
  3: [
    {
      type: "paragraph",
      text:
        "In addition to your offer price, there are a few other decisions that you’ll need to include as part of your offer",
    },
    {
      type: "paragraph",
      text:
        "The first is the amount of money you’ll put into escrow as your earnest deposit. In order to be in contract in New York, you’ll often need to produce at least 10% of the purchase price. Depending on how competitive the market is, you may want to offer an even higher earnest deposit to show you’re serious about buying the property. Your check is then deposited with the seller’s attorney and the seller can get the money if you back out of the deal. If you close, the cash is put towards your down payment and/or closing costs.",
    },
    {
      type: "paragraph",
      text:
        "Contingencies are another part of the purchase process. These outline the necessary conditions to close the deal. The most common contingencies include a reasonable home inspection, successful buyer financing, a high enough appraisal, and a clean title on the property. If you need to sell your current home before closing on a new property, you can also include a home sale contingency in your offer. But this one is less popular because sellers are less likely to accept an offer with so much uncertainty. ",
    },
    {
      type: "paragraph",
      text:
        "Also include a closing date and some financing details, such as the mortgage type and down payment amount. A seller may prefer one type of mortgage over another, or choose a buyer with a large down payment, simply because it improves the odds of financing approval. ",
    },
    {
      type: "paragraph",
      text:
        "Once the seller reviews your offer, they have the option to accept it as-is, decline it altogether, or come back with a counter-offer on price and potentially the closing date. Your real estate agent should help you navigate the negotiation process. Once you finalize the details with the seller, you’ll both sign the paperwork and you’ll officially be under contract.",
    },
  ],
  4: [
    {
      type: "paragraph",
      text:
        "A home inspection helps you get to know the property after you’re under contract but before you reach closing. Once you get a closer look at the home’s condition, you can initiate another round of negotiations with the seller. Although it’s not required, an inspection is a crucial part of the home buying process.",
    },
    {
      type: "paragraph",
      text:
        "Because the inspection is strictly for your benefit, you have total freedom in choosing your inspector. Depending on the size of the home, the cost could range from $300 to $500 and take several hours to complete. Expect to be on-site during the entire home inspection so you can receive a first-hand account of the results. It’s also helpful to be there so you can ask questions or point out concerns you may have.",
    },
    {
      type: "paragraph",
      text:
        "Your home inspector assesses the structural integrity of the home and checks to see if key features work correctly. They’ll look for the following issues during the inspection:",
    },
    {
      type: "dotsParagraph",
      texts: [
        "Heating and cooling systems",
        "Structural foundation",
        "Plumbing and electric",
        "Roof",
        "Water damage",
        "Attic and insulation",
        "Exterior siding",
      ],
    },

    {
      type: "paragraph",
      text:
        "Typically, a home inspection does not include pests or termites, septic systems, or fireplaces and chimneys. You can schedule additional inspections for these additional features in your future home.",
    },
    {
      type: "paragraph",
      text:
        "Once the inspection is complete, you’ll receive a formal report within a few days. It outlines the condition of the areas inspected and areas that potentially need attention. Many inspectors these days also include photos so you know exactly what they report is referring to.",
    },
    {
      type: "paragraph",
      text:
        "There are a couple of things to keep in mind when you receive your inspection report. The first is that there’s no pass/fail and there’s really no such thing as a perfect inspection. In other words, expect to see some issues come up. The other thing to remember is that a home inspection is primarily based on visual observation. You may later discover hidden issues that the inspector missed — which is unfortunately part of the homeownership experience.",
    },
    {
      type: "paragraph",
      text:
        "After you review the home inspection report, you have three different options moving forward. If you’re satisfied with the results, you can simply move on with the closing process under the existing contract. Alternatively, you could opt to renegotiate the contract with the seller. Finally, you could potentially walk away from the deal if there are major issues with the home.",
    },
  ],
  5: [
    {
      type: "paragraph",
      text:
        "Even though you agree on a purchase price and other details when you first make an offer on a home, the contract isn’t finalized until the day you close and get that title signed over in your name. Here’s what can happen in the meantime.",
    },

    {
      type: "paragraph",
      text:
        "The first major thing that happens is that your earnest deposit check will be cashed and held in an escrow account. Once the deal closes, that money is applied to your down payment and other closing costs. Next, you need to get moving on your mortgage application. You’ve probably already been pre-approved with a lender, so find out what other information or documents they need to get your application moving.",
    },

    {
      type: "paragraph",
      text:
        "After you go through the home inspection process, figure out whether or not you want to try and negotiate the contract. You know now that all homes will probably have some kind of issue. But if you’re not overwhelmed (either from a skill set perspective or a financial perspective), then you can just move forward with the contract as is.",
    },

    {
      type: "paragraph",
      text:
        "But if the home’s condition isn’t as you expected, you can renegotiate with the seller, either by requesting a lower purchase price, asking for seller-made repairs, or receiving a credit at closing. You can then use that money to make the repairs on your own. That way, the seller doesn’t have to worry about the extra stress while moving and you can pick the materials and contractor you want. If you ask for any repairs to be made by the seller, designate whether or not you’d like them to be completed by a licensed contractor. Then ask for proof of completion, such as invoices or receipts.",
    },

    {
      type: "paragraph",
      text:
        "In some situations, you may be able to walk away from the contract altogether if the home has serious issues. Get your real estate agent or attorney to review the language in your contract to see whether or not you would lose your earnest money deposit.",
    },

    {
      type: "paragraph",
      text:
        "Once that final round of negotiations is complete, you’ll have a much clearer path to closing.",
    },
  ],
  6: [
    {
      type: "paragraph",
      text:
        "Homeowners insurance is required when you’re using a mortgage to pay for your new home (and smart to have even if you’re paying in cash). Start looking for a policy at least a month before you close, otherwise you risk not getting coverage in time and delaying the transaction. You must provide proof of your policy in order to successfully close.",
    },

    {
      type: "paragraph",
      text:
        "It’s also wise to shop around for quotes from different insurers. Your annual premium depends on how much coverage you want and the size of your deductible, so compare both price and quality from each carrier. Also ask about how the claims process works. If a company has a reputation for poor customer service when something happens to your home, you may not want to work with them.",
    },

    {
      type: "paragraph",
      text:
        "The type of policy you get depends on your property, including whether it’s a single family home or a condo. A policy for a single family home, for example, will cover the entire structure, while condo insurance only covers your unit (since the homeowners association is responsible for the building itself).",
    },

    {
      type: "paragraph",
      text:
        "The insurance company should give you a replacement cost estimate for the home, as well as coverage for your personal property. You’ll also get a recommendation on how much liability coverage to include, which pays for medical costs if anyone is injured on your property.",
    },

    {
      type: "paragraph",
      text:
        "Homeowners insurance covers damage caused by certain events, such as fire, theft, or certain natural disasters. In most places, you’ll need a policy add-on for events like hurricanes, earthquakes, or flooding. Consider how common each one is in your area to determine whether or not it’s worth the additional cost.",
    },

    {
      type: "paragraph",
      text:
        "When you pick a policy, the annual premium is typically included as part of your monthly mortgage payment. You may pay some or all of the first year as part of your closing costs. After that, the annual premium is divided among your 12 mortgage payments. The money is held in an escrow account and then paid to the insurance company by your loan servicer before the due date.",
    },
  ],
  7: [
    {
      type: "paragraph",
      text:
        "The mortgage application process should start before you begin house hunting and it ends on closing day. Start by getting pre-approved with your lender of choice. This usually involves a verbal exchange of information, such as your income, current debts, credit score, and down payment amount. Your lender can then give you an idea of the best type of mortgage suited for your needs. You’ll also learn your maximum loan amount and figure out your real house budget based on an interest rate quote and estimated monthly payment. With a pre-approval in hand, you’re ready to make an offer on your new home.",
    },

    {
      type: "paragraph",
      text:
        "Once you’re officially under contract, you can formally submit your mortgage application with your lender. You’ll need to fill out paperwork about your finances and employment. Expect the lender to request some documentation as well, such as tax returns, pay stubs, and bank statements. The lender also performs a hard credit check to get an accurate credit score and interest rate for your loan. ",
    },

    {
      type: "paragraph",
      text:
        "Next, the loan goes to an underwriter to evaluate your application. You don’t need to do anything unless your loan officer requests additional paperwork or a letter of explanation for some of your financials. When that happens, respond as quickly as possible so you don’t slow down your approval — which can delay closing.",
    },

    {
      type: "paragraph",
      text:
        "While your application is in underwriting, your lender will also order an appraisal for the home. It’s similar to an inspection in that a third-party appraiser goes to the property to evaluate its condition. You’ll receive an appraisal report and a value amount, which needs to be greater than or equal to the purchase price. That’s because a lender can’t approve a mortgage for more than the home is worth. If for some reason the appraisal comes back too low, you either need to make a larger down payment or renegotiate the purchase price with the seller.",
    },

    {
      type: "paragraph",
      text:
        "Assuming the property does appraise high enough and your application clears underwriting, you should get approved for your mortgage.",
    },
  ],
  8: [
    {
      type: "paragraph",
      text:
        "The final walkthrough is your chance to make sure the sellers have completely moved out and left the home in an acceptable condition. Also check that everything they agreed to leave behind is still there, such as appliances. ",
    },
    {
      type: "paragraph",
      text:
        "Both you and the seller should agree on a date and time for the walkthrough via your realtors. In most cases, it should take place a day or two before closing. If the seller wasn’t already moved out when you saw the home, they should definitely have all of their belongings out by this point. ",
    },
    {
      type: "paragraph",
      text:
        "Not only should all of the current owners belongings be gone, also confirm that any negotiated repairs have been taken care of. Additionally, look for any new or hidden issues with the home. Test the faucets to make sure there are no leaks, flush the toilets to ensure they work properly, and see if there’s any damage to the walls either from moving or large pictures. The home should also be reasonably clean, with the floors swept or vacuumed. ",
    },
    {
      type: "paragraph",
      text:
        "So what happens if there’s an issue during your walkthrough? It’s back to the negotiation table and you’ll need to request that the seller address the problem areas. Take pictures to send to the seller along with your concerns. Depending on the scope of the problem, closing may need to be pushed back in order to get the issues fixed. In some cases, the seller may be able to arrange for things to be addressed in time. ",
    },
    {
      type: "paragraph",
      text:
        "Alternatively, you could ask for cash at closing to address the issue. Either way, it’s important to give a little bit of time between the walkthrough and closing so you can negotiate and give the seller time to meet your needs. Ideally, though, your walkthrough will be drama-free and you’ll be one step closer to closing on your home. ",
    },
  ],
  9: [
    {
      type: "paragraph",
      text:
        "Closing day is the big moment where you finalize your purchase and get the keys to your new home. As the buyer, you’ll typically close after the seller has performed their part of signing the paperwork and paying any closing costs or negotiated repair costs that aren’t coming out of the sale proceeds.",
    },
    {
      type: "paragraph",
      text:
        "Before you arrive at your closing appointment, you should receive most of the paperwork a few days in advance. It’s important to review this information, especially the loan estimate from your lender. It shows you the finalized monthly mortgage payment as well as how much cash you need to bring to closing. Remember that your earnest money deposit should be deducted from this total.",
    },
    {
      type: "paragraph",
      text:
        "You must get a cashier’s check for this amount or arrange to wire the money to the closing agent. Both options take some preparation. Your bank can help you with both, but give yourself time. You need to get a cashier’s check in person and while you may initiate a wire transfer online, it’s not instantaneous. Also bring your photo ID with you.",
    },
    {
      type: "paragraph",
      text:
        "Check to make sure where your closing will take place; often, it will be at your realtor’s office. When you arrive, expect to be there for at least an hour to sign all of the paperwork. Documents include the property deed and mortgage paperwork. Read the paperwork carefully so you know what you’re signing. You’ll also leave with a copy of everything for your own records. When you move into your new home, put all of your closing documents in a safe place.",
    },
    {
      type: "paragraph",
      text:
        "Once everything is signed, the deed is recorded in your name and your realtor will give you the keys to the property. Now you’re ready to move in and enjoy your new home after weeks of preparation.",
    },
  ],
  10: [
    {
      type: "paragraph",
      text:
        "While moving day is sure to be busy, you can get started with several tasks ahead of time. First, request a change of address with the post office (you can pick the effective date). Also arrange for utilities and internet service to be activated in your name by your move-in date. Remember to also deactivate your utilities at your current home.",
    },
    {
      type: "paragraph",
      text:
        "Next, schedule movers or reserve a moving truck at least a month in advance. Weekends and the first week of the month are the busiest times, so the sooner you get this done, the better. Start accumulating boxes and other supplies and begin packing sooner rather than later. There’s nothing more stressful than being behind on packing when you’re also dealing with the other steps of a home closing.",
    },
    {
      type: "paragraph",
      text:
        "On the day of the move, set aside any valuables to ride in the car with you. Also pack an overnight box to have nearby. Include your overnight toiletries, a shower curtain, coffee maker, and anything else you’ll need right away.",
    },
    {
      type: "paragraph",
      text:
        "Stock up on water and cash for tips if you’ve hired movers. Prepare to be on-site at your old place and your new home to direct where you want furniture and boxes placed. Also, whether you’re leaving a rental or selling your old home, leave it in clean condition — just as you expect your new home to be during your final walkthrough.",
    },
    {
      type: "paragraph",
      text:
        "Once you’re in your new home, remember to change vehicle tags and update other contact information, such as with schools and healthcare providers. ",
    },
  ],
};
