import { ActionTypes } from '../actions';

const defaultState = {
    isLoadingPaymentIntent: true,
    paymentIntent: {}
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADING_PAYMENT_INTENT:
                return {
                    ...state,
                    isLoadingPaymentIntent: true,
                };

            case ActionTypes.LOADED_PAYMENT_INTENT:
                return {
                    ...state,
                    isLoadingPaymentIntent: false,
                    paymentIntent: action.payload
                };

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in settingsState reducer: ${ error.message || error.code || error }`, error);
    }
};
