import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Button.module.scss'

export default function HyponiaButton({ className, children, secondary = false, updating = false, ...other }) {
  return <Button
    className={classNames(
      styles.hyponiaButton,
      {
        [styles.hyponiaButtonSecondary ]: secondary,
        [styles.updating ]: updating
      },
      className
    )}
    {...other}
  >
    {children}
  </Button>
}

HyponiaButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  secondary: PropTypes.bool,
  updating: PropTypes.bool
}
