import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ClientCard from "./ClientCard";
import "./index.scss";

import TimelineContainer from "../../containers/Timeline";

const Clients = ({ clients, handleClick, openTimeline }) => {
  return (
    <>
      {openTimeline ? (
        <TimelineContainer />
      ) : (
        <div id={"professional-clients"} className={"dashboard--gutters"}>
          <Container>
            <Row className="justify-content-md-center">
              <Col xs={12} md={9}>
                <h2 className={"remove--gutters mt-4"}> Clients </h2>
                <div className={"details-container"}>
                  <div className="table-header">
                    <Row>
                      <Col sm={3}>
                        <p> First Name</p>
                      </Col>
                      <Col sm={3}>
                        <p>Last Name</p>
                      </Col>
                      <Col sm={3}>
                        <p>Email</p>
                      </Col>
                      <Col sm={3}>
                        <p>Phone</p>
                      </Col>
                    </Row>
                    <hr className="content-border"></hr>
                  </div>

                  {clients.map((client) => (
                    <ClientCard
                      onClick={() => handleClick(client.UserObjectId)}
                      key={client.UserObjectId}
                      client={client}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Clients;
