import React from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Steps from "../../components/Steps";
import classnames from "classnames";
import "./index.scss";

const Onboarding = (props) => {
  const stepId = `step-${props.currentStep}`;
  //const stepId = "step-4"
  return (
    <div id={"rui__onboarding-container"}>
      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={props.currentStep}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames={classnames({ "is-back": props.isBack }, "fade")}
        >
          <Steps
            {...props}
            stepId={stepId}
            handleBack={props.handleOnboardingBack}
            handleNext={() =>
              props.doSaveOnboardingStep({ stepId, step: props.values[stepId] })
            }
            handleChange={(value, k) => props.onboardingStepChange({
              stepId,
              value,
              key: k || props.steps[stepId].key
            })}
          />
        </CSSTransition>
      </SwitchTransition>

      <div className="mobile-hide">
        <p className="mt-3 mb-0" style={{ textAlign: "center" }}>
          Call us 551-998-6154
        </p>

        <p className="mt-0 " style={{ textAlign: "center", cursor: "pointer" }}>
          <a
            onClick={() =>
              window.open("https://www.hyponia.com/privacy-policy", "_blank")
            }
            className="onboarding-link"
          >
            {" "}
            Privacy Policy
          </a>
          | {" "}
          <a
            onClick={() =>
              window.open("https://www.hyponia.com/terms-of-use", "_blank")
            }
            className="onboarding-link"
          >
            {" "}
            Terms of Use
          </a>
        </p>
      </div>
    </div>
  );
};

export default Onboarding;
