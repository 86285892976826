import {call, put, select} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {loadSummary, loadTimelines, saveSummary} from './networks';

export default [
    [ActionTypes.GET_SUMMARY, getSummary],
    [ActionTypes.DO_SAVE_SUMMARY, doSaveSummary],
];

const getTimelineState = (state) => state.timeline;

function * getSummary ({ payload }) {
    yield put(Actions.loadingSummary());

    const response = yield call(loadSummary, payload);

    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedSummary(response.data));
    }
}

function * doSaveSummary ({ payload: summaryDetails }) {
    yield put(Actions.doingSaveSummary());

    let timeline = yield select(getTimelineState);
    const payload =  {
        TimelineId: timeline.selectedTimelineId,
        Details: summaryDetails
    };

    const response = yield call(saveSummary, payload);

    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.doneSaveSummary(response.data));
    }
}