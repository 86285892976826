import React, {Component} from 'react';
import Dashboard from "../pages/Dashboard";
import {connect} from "react-redux";
import {Actions} from "../actions";
import Loader from "../utils/Loader";

class DashboardContainer extends Component {
    render() {
        const { isProfessionalDetailsLoading, professionalDetails, history } = this.props;
        if (isProfessionalDetailsLoading) {
            return <Loader />;
        }

        if (!professionalDetails.FirstName) {
            history.push({pathname: "/profile", state: { editable: 1 }})
            return null;
        }

        return <Dashboard { ...this.props }/>;
    }
}

export default connect(state => state.professionals, Actions)(DashboardContainer);
