import React from 'react';
import { Col, Row, Button } from "react-bootstrap";
import Link from '../../../utils/Link';
import {validateName,telephoneCheck} from "../../../utils"
import classNames from 'classnames';
import './index.scss';

const Actions = ({ type, currentStep, handleNext, selectedValue, totalSteps, inTraffic, handleBack, step }) => {

  var isEnabled = false;

  switch (step.key) {
    case 'BusinessDetailsOne':
      isEnabled = selectedValue &&
        (
          (
            selectedValue.FirstName &&
            selectedValue.LastName &&
            validateName(selectedValue.FirstName) &&
            validateName(selectedValue.LastName)
          ) || selectedValue.DisplayContact === false
        ) &&
        (selectedValue.DisplayContact === true || selectedValue.DisplayContact === false) &&
        selectedValue.CompanyName;
      break;


    case 'BusinessDetailsTwo':
      isEnabled = selectedValue &&
        (selectedValue.Address || selectedValue.NoPhysicalAddress) &&
        selectedValue.Phone &&
        telephoneCheck(selectedValue.Phone)
      break;

    default:
      isEnabled = selectedValue && !!selectedValue.length;
  }

  const isSkippable = step.key === "BusinessDetailsTwo"
  const isNextVisible = ["textbox", "checkboxes", "mixed"].indexOf(type) > -1;
  return (

    <Col id={"rui__actions-container"}>
      <Row className={"centered"}>
        <Col>
          {
            currentStep != 1
              ? <a onClick={handleBack}>Go Back</a>
              : null
          }
        </Col>
        <Col className="skip-next-container d-flex">
          {
            isNextVisible
              ? <div
                className={
                  classNames("btn btn-primary", {
                    disabled: inTraffic || !isEnabled
                  }
                  )}
                onClick={() => handleNext()}
              >
                {
                  currentStep === totalSteps
                    ? "Submit"
                    : "Next"
                }
              </div>
              : null
          }
          {
            isSkippable?
            <a onClick={()=>handleNext()} className="ml-4">Skip</a>:
              null
          }
        </Col>
        <Col>
          <p>Step <span className={"current-step"}>{currentStep}</span> of {totalSteps}</p>
        </Col>
      </Row>
    </Col>
  );
};

export default Actions;
