import React from "react";
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Container
} from "react-bootstrap";

import styles from './Layout.module.scss'

export default function Layout({ children, title, classes }) {

  return <div className={styles.root}>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
    <Container>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <h2>{title}</h2>
          <Row className={classes?.row||""}>
            {children}
          </Row>
        </Col>
      </Row>
    </Container>
  </div>

}

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  classes: PropTypes.object
}
