import React from "react"
import { Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types'

import DashboardCardWithSave from "../../components/DashboardCardWithSave"
import FormControl from "../../components/FormControl"
import _ from "underscore"

export default function OnlineAccount({  professionalDetails, savePassword, inTraffic}) {

  const [values, setValues] = React.useState({
    ...professionalDetails
  });

  const passDefaultValue = "##**##**##**"

  const [pass,setPass] = React.useState(passDefaultValue)

  const _handleSave = () => {
    savePassword(pass)
  }

  React.useEffect(()=>{
    if(!inTraffic){
      setPass(passDefaultValue)
    }
  },[inTraffic])

  return <DashboardCardWithSave
    handleSave={_handleSave}
    isSaving={inTraffic}
    title="Online account"
    xs={12}
    hideSave={_.isEqual(pass, passDefaultValue)}
    saveDisabled={!pass}
  >
    <Row>
      <Col lg="6" md="8">
        <FormControl
          label="Account email"
          disabled
          type="email"
          value={values.Email}
          onChange={() => { }}
        />
        <FormControl
          label="Account password"
          type="password"
          defaultValue={passDefaultValue}
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          className="mb-0"
        />
      </Col>
    </Row>
  </DashboardCardWithSave>
}

OnlineAccount.propTypes = {
    professionalDetails: PropTypes.object,
    savePassword: PropTypes.func,
    inTraffic: PropTypes.bool
}
