import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Dropdown,
  FormLabel,
  InputGroup,
} from "react-bootstrap";
import classNames from "classnames";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { isMobile } from "react-device-detect";
import {
  formatPhoneNumber,
  numberWithCommas,
  getFormattedAddress,
  validateName,
  stringifyAddress
} from "../../../utils";
import states_cities from "../../../utils/states_cities";
import "./index.scss";
import Autocomplete from "react-autocomplete";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";

import FormControl from "../../FormControl"

const Options = ({
  step,
  selectedValue = "",
  handleNext,
  handleChange,
  currentStep,
}) => {

  const handleAreasCheckbox = (e) => {
    let selectedCheckboxes = selectedValue || []

    const index = selectedCheckboxes.findIndex(c => c === e.target.name)
    if (e.target.checked && index === -1) {
      handleChange([...selectedCheckboxes, e.target.name])
    } else if (!e.target.checked && index !== -1) {
      selectedCheckboxes.splice(index, 1)
      handleChange(selectedCheckboxes)
    }
  }

  const handleTextMultiChange = (val, subkey, key) => {

    let valueToBeSet = selectedValue || {};

    if (subkey === "FirstName" || subkey === "LastName") {
      if (validateName(val)) {
        valueToBeSet[subkey] = val;
        handleChange(valueToBeSet, key)
      }
    } else if (subkey === "Phone") {
      const value = formatPhoneNumber(val);
      if (
        value ||
        value == ""
      ) {
        valueToBeSet[subkey] = value;
        handleChange(valueToBeSet, key)
      }
    } else {
      valueToBeSet[subkey] = val;
      handleChange(valueToBeSet, key)
    }


  }

  const handleRadiosMultiChange = (e, subkey, key) => {

    let valueToBeSet = selectedValue || {};

    if (key === "BusinessDetailsOne") {
      valueToBeSet[subkey] = e.target.parentElement.innerText === "Business name and Contact name"
    } else if (key === "BusinessDetailsTwo") {
      valueToBeSet[subkey] = e.target.checked
      if (e.target.checked) {
        valueToBeSet.CurrentAddress = ""
      }
    }

    handleChange(valueToBeSet, key)
  }

  const disabledCheck = (subkey, key) => {
    if (key === "BusinessDetailsOne") {
      if (subkey === "FirstName" || subkey === "LastName") {
        return selectedValue.DisplayContact === false
      }
    }

    return false
  }

  const radiosCheck = (label, subkey, key) => {
    if (key === "BusinessDetailsOne") {
      return label === "Business name and Contact name" ?
        (selectedValue[subkey] === true) :
        (selectedValue[subkey] === false)
    } else if (key === "BusinessDetailsTwo") {
      return selectedValue[subkey]
    }
    return false
  }

  const shouldHaveMargin = (subkey) => {
    return subkey === "LastName" ||
      subkey === "CompanyName" ||
      subkey === "Phone" ||
      subkey === "Website"
  }

  const renderLocation = (key, ops, subKey) => ops.map((option, idx) => {
    return <FormControl
      key={idx}
      disabled={selectedValue.NoPhysicalAddress || false}
      label="Address"
      type="address"
      className="text-left centered-form-group"
      value={selectedValue[subKey]}
      onChange={(e) => handleTextMultiChange(e, subKey, key)}
      onSelect={(results) => handleTextMultiChange(stringifyAddress(getFormattedAddress(results[0])), subKey, key)}
    />
  });

  const _getOptions = (step) => {

    switch (step.type) {
      case "select":
        return step.options.map((option, i) => (
          <Col xs={6} md={4} className={"flexbox-col centered"} key={i}>
            <div
              className={classNames("select-area flexbox centered", {
                selected: selectedValue === option.value,
              })}
              onClick={() => handleNext(option.value)}
            >
              {option.icon ? (
                <i
                  className={
                    "icon fa fa-" + option.icon + " icon-" + option.icon
                  }
                ></i>
              ) : (
                  <p className={"option-title"}>{option.title}</p>
                )}
            </div>
            {option.icon && <p className={"option-title"}>{option.title}</p>}
          </Col>
        ));

      case "checkboxes":
        return (
          <Col>
            <Form.Group controlId="formBasicCheckbox" className="checkbox-input text-left">
              <FormControl
                type="checkboxes"
                options={step.options.map(option => {
                  return {
                    label: option,
                    checked: (selectedValue || []).findIndex(sc => sc === option) !== -1
                  }
                })}
                onChange={handleAreasCheckbox}
              />
            </Form.Group>
          </Col>
        )

      case "mixed":
        return <Col>
          <Form>
            {
              step.options.map((option, i) => {

                if (option.type === "text" || option.type === "tel") {

                  return <FormControl
                    className={classNames("text-left centered-form-group", shouldHaveMargin(option.key) ? "mb-4" : "")}
                    key={i}
                    label={option.label}
                    value={selectedValue[option.key] || ""}
                    type={option.type}
                    onChange={(e) => {
                      let { value } = e.target;
                      if (option.key) {
                        handleTextMultiChange(value, option.key, step.key)
                      }
                    }}
                    disabled={disabledCheck(option.key, step.key)}
                  />
                } else if (option.type === "multitext") {
                  return <FormControl
                    type="multitext"
                    className="text-left centered-form-group mb-4"
                    label={option.label}
                    value={selectedValue[option.key] || []}
                    onChange={allItems => {
                      handleTextMultiChange(allItems, option.key, step.key)
                    }}
                  />
                } else if (option.type === "radios") {
                  return <FormControl
                    key={i}
                    className="text-left centered-form-group"
                    label="Should we display:"
                    type="radios"
                    name={option.key}
                    options={option.options.map(o => {
                      return {
                        label: o.label,
                        checked: radiosCheck(o.label, option.key, step.key)
                      }
                    })}
                    onChange={(e) => handleRadiosMultiChange(e, option.key, step.key)}
                  />
                } else if (option.type === "location") {
                  return renderLocation(step.key, option.options, option.key)
                } else if (option.type === "checkboxes") {
                  return <FormControl
                    key={i}
                    type="checkboxes"
                    className="text-left centered-form-group"
                    options={option.options.map(o => {
                      return {
                        label: o.label,
                        checked: radiosCheck(o.label, option.key, step.key)
                      }
                    })}
                    onChange={(e) => handleRadiosMultiChange(e, option.key, step.key)}
                  />
                } else if (option.type === "info") {
                  return <FormControl
                    label={option.label}
                    type="info"
                    value=""
                    className="mb-0 text-left centered-form-group font-weight-bold"
                  />
                }

                return null;
              })
            }
          </Form>
        </Col>

      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Col id={"rui__options"} className="mt-3 mb-4">
        <Row
          className={classNames("all-options", {
            centered: ["select", "boolean"].indexOf(step.type) === -1,
          })}
        >
          {_getOptions(step)}
        </Row>
      </Col>
      {/*<Actions type={step.type} currentStep={currentStep} handleNext={() => handleNext(selectedValue)}/>*/}
    </React.Fragment>
  );
};

export default Options;
