import React, {Component} from 'react';
import {connect} from 'react-redux';
import Settings from '../pages/Settings';
import { Actions } from "../actions";
import Loader from "../utils/Loader";

class SettingsContainer extends Component {
    componentWillMount() {
        this.props.getProfessionalDetails();
        this.props.getProfessionalSettings();
        // this.props.getPaymentIntent();
    }

    render() {
        if (this.props.professionals.isProfessionalDetailsLoading || this.props.professionals.isProfessionalSettingsLoading) {
            return Loader();
        }

        return <Settings { ...this.props }/>;
    }
}

export default connect(state => ({
    professionals: state.professionals,
    settings: state.settings,

}), Actions)(SettingsContainer);
