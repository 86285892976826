import React from "react";

import "./index.scss";
import ReviewsComponent from "../../components/Reviews";
import Layout from "../../components/Layout";

export default function Reviews({professionalDetails}) {

  const {
    Reviews
  } = professionalDetails

  return (
    <Layout title="Reviews">
      <ReviewsComponent reviews={Reviews||[]} />
    </Layout>
  );
}

const sampleArr = [
  {
    score: 2.5,
    date: new Date(),
    title: "Title",
    comment: `Deserunt ipsum nulla nisi Lorem Lorem quis ullamco esse occaecat
cillum fugiat proident velit. Exercitation officia minim excepteur
qui do quis culpa reprehenderit culpa mollit deserunt duis
consequat. Laborum consequat est sint sit eiusmod. Lorem eiusmod
eiusmod ex officia ad quis occaecat sunt ea dolor deserunt.`,
    name: "Name",
    location: "Location",
  },
  {
    score: 3.5,
    date: new Date(),
    title: "Title 2",
    comment: `Deserunt ipsum nulla nisi Lorem Lorem quis ullamco esse occaecat
cillum fugiat proident velit. Exercitation officia minim excepteur
qui do quis culpa reprehenderit culpa mollit deserunt duis
consequat. Laborum consequat est sint sit eiusmod. Lorem eiusmod
eiusmod ex officia ad quis occaecat sunt ea dolor deserunt.`,
    name: "Name 2",
    location: "Location",
  },
  {
    score: 4,
    date: new Date(),
    title: "Title 3",
    comment: `Deserunt ipsum nulla nisi Lorem Lorem quis ullamco esse occaecat
cillum fugiat proident velit. Exercitation officia minim excepteur
qui do quis culpa reprehenderit culpa mollit deserunt duis
consequat. Laborum consequat est sint sit eiusmod. Lorem eiusmod
eiusmod ex officia ad quis occaecat sunt ea dolor deserunt.`,
    name: "Name 3",
    location: "Location",
  },
];

// import React from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import "./index.scss";

// const Reviews = () => {
//   return (
//     <div>
//       <div id={"professional-reviews"} className={"dashboard--gutters"}>
//         <Container>
//           <Row className="justify-content-md-center">
//             <Col xs={12} md={8}>
//               <h2 className={"remove--gutters mt-4"}> Reviews </h2>
//               <div className={"details-container"}>
//                 <div className={"content"}><h4>You don't have any Reviews yet</h4></div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//       );
//     </div>
//   );
// };

// export default Reviews;
