import React, { Component } from "react";
import Clients from "../pages/Clients";
import { connect } from "react-redux";
import { Actions } from "../actions";

class ClientsContainer extends Component {
  state = {
    loading: "",
  };

  componentWillMount = () => {
    this.props.doGetClients();
  };

  _handleClick = (UserObjectId) => {
    this.props.doGetClientTimeline(UserObjectId);
  };

  render() {
    return (
      <Clients handleClick={this._handleClick.bind(this)} {...this.props} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.clients,
  };
};
export default connect(mapStateToProps, Actions)(ClientsContainer);
