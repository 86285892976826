import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import classNames from "classnames";
import ImageUploader from "react-images-upload";
import { isMobile } from "react-device-detect";
import {
  validateName,
  formatPhoneNumber,
  getFormattedAddress,
  stringifyAddress,
  telephoneCheck,
  yearCheck,
} from "../../utils";
import _ from "underscore";

import DashboardCardWithSave from "../DashboardCardWithSave";
import FormControl from "../FormControl";

import styles from "./ProfessionalDetail.module.scss";
import moment from "moment";

const ProfessionalDetail = ({
  professional,
  handleSave,
  isSaving,
  categories,
  isEditable,
  icon,
  doSaveProfileImage,
}) => {
  const workingSinceDate = moment(professional.WorkingSince);

  let professionalSlice = {
    ...professional,
    ServiceState: Object.keys(professional.ServiceAreas)[0],
    ServiceCities:
      professional.ServiceAreas[Object.keys(professional.ServiceAreas)[0]],
    WorkingSinceMonth: workingSinceDate._isValid
      ? workingSinceDate.format("MM")
      : "",
    WorkingSinceYear: workingSinceDate._isValid
      ? workingSinceDate.format("YYYY")
      : "",
    ReviewsLinks: professional.ReviewsLinks ? professional.ReviewsLinks : [],
    Intro: professional.Intro ? professional.Intro: ''
  };

  delete professionalSlice.WorkingSince;
  delete professionalSlice.Password;
  delete professionalSlice.ServiceAreas;
  delete professionalSlice.CategoryName;

  const [fieldVal, setFieldVal] = React.useState(professionalSlice);

  const [photo, setphoto] = useState(professional.PhotoLink);
  const [photoUri, setphotoUri] = useState(null);
  const [uploaded, setuploaded] = useState(false);
  const uploadRef = useRef(null);

  const _setFieldVal = (key, val) => {
    setFieldVal({
      ...fieldVal,
      [key]: val,
    });
  };

  const _batchSetFieldVal = (obj) => {
    setFieldVal({
      ...fieldVal,
      ...obj,
    });
  };

  useEffect(() => {
    getPicture(photo);
  }, [photo, getPicture]);

  const _handleSave = () => {
    const newFieldVal = { ...fieldVal };
    const newProfessional = { ...professional };

    newFieldVal.Address = fieldVal.Address || "";
    newFieldVal.CategoryId = fieldVal.CategoryId;
    newFieldVal.ServiceAreas = {
      [fieldVal.ServiceState]: fieldVal.ServiceCities,
    };
    newFieldVal.WorkingSince = `${fieldVal.WorkingSinceYear}-${fieldVal.WorkingSinceMonth}-01T00:00:00`;

    delete newFieldVal.WorkingSinceYear;
    delete newFieldVal.WorkingSinceMonth;
    delete newFieldVal.ServiceState;
    delete newFieldVal.ServiceCities;
    delete newProfessional.PhotoLink;
    delete newProfessional.Password;
    handleSave({
      ...newProfessional,
      ...newFieldVal,
    });
  };

  const onDrop = (picture) => {
    setphoto(picture[0]);
    setuploaded(true);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function getBase64(file) {
    setphotoUri(await toBase64(file));
    console.log(doSaveProfileImage);
    doSaveProfileImage({
      image: await toBase64(file),
      guid: localStorage.getItem("guid"),
    });
  }

  function getPicture() {
    if (photo) {
      if (uploaded) {
        getBase64(photo);
      } else {
        setphotoUri(photo);
      }
    } else {
      setphotoUri(
        "https://netstock-europe.com/wp-content/uploads/2016/09/fg-avatar-anonymous-user-retina-300x300.png"
      );
    }
  }

  const isEnabled = true

  const isNameCardEnabled = ((fieldVal.FirstName &&
    fieldVal.LastName &&
    validateName(fieldVal.FirstName) &&
    validateName(fieldVal.LastName)) ||
    fieldVal.DisplayContact === false) &&
  (fieldVal.DisplayContact === true || fieldVal.DisplayContact === false) &&
  fieldVal.CompanyName

  const isCategoryCardEnabled = fieldVal.CategoryId &&((fieldVal.WorkingSinceYear &&
    yearCheck(fieldVal.WorkingSinceYear) &&
    fieldVal.WorkingSinceYear.length === 4 &&
    +fieldVal.WorkingSinceYear >= 1950) || !fieldVal.WorkingSinceYear)

  const isContactCardEnabled = fieldVal.Phone && telephoneCheck(fieldVal.Phone) && (fieldVal.Address || fieldVal.NoPhysicalAddress)

  const isCitiesCardEnabled = fieldVal.ServiceCities.length > 0

  return (
    <Row className="flex-column">
      <DashboardCardWithSave
        isSaving={isSaving}
        handleSave={_handleSave}
        hideSave={_.isEqual(
          {
            PhotoLink: fieldVal.PhotoLink,
            FirstName: fieldVal.FirstName,
            LastName: fieldVal.LastName,
            CompanyName: fieldVal.CompanyName,
            DisplayContact: fieldVal.DisplayContact,
          },
          {
            PhotoLink: professionalSlice.PhotoLink,
            FirstName: professionalSlice.FirstName,
            LastName: professionalSlice.LastName,
            CompanyName: professionalSlice.CompanyName,
            DisplayContact: professionalSlice.DisplayContact,
          }
        )}
        saveDisabled={!isNameCardEnabled}
      >
        <Row className={`mt-md-4 mt-3 ${styles['right-box']}`}>
          <Col
            md="auto"
            className="mb-2 d-flex flex-column align-items-center d-md-block"
          >
            <div
              onTouchStart={() => {
                uploadRef.current.triggerFileUpload();
              }}
              className={classNames(
                styles.userImg,
                "d-flex align-items-center justify-content-center"
              )}
              style={{ backgroundImage: `url(${photoUri})` }}
            >
              <ImageUploader
                withIcon={true}
                singleImage
                ref={uploadRef}
                buttonText="Choose images"
                onChange={onDrop}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                maxFileSize={5242880}
                style={{ display: "none" }}
              />
              <Button
                onClick={() => uploadRef.current.triggerFileUpload()}
                className={styles.uploadPhotoBtn}
                variant="primary"
              >
                {!isMobile && <small>upload photo</small>}
              </Button>
            </div>
            {isMobile && <p className="m-0 d-md-none">Touch to edit photo</p>}
          </Col>
          <Col
            md="auto"
            xs={12}
            className={classNames(
              styles.content,
              "flex-md-grow-1 flex-md-shrink-1"
            )}
          >
            <Row>
              <Col lg={6}>
                <FormControl
                  controlId="firstname"
                  label="First Name"
                  type="text"
                  value={fieldVal.FirstName}
                  onChange={(e) => {
                    if (validateName(e.target.value)) {
                      _setFieldVal("FirstName", e.target.value);
                    }
                  }}
                  disabled={fieldVal.DisplayContact === false}
                />
              </Col>
              <Col lg={6}>
                <FormControl
                  controlId="lastname"
                  label="Last Name"
                  type="text"
                  value={fieldVal.LastName}
                  onChange={(e) => {
                    if (validateName(e.target.value)) {
                      _setFieldVal("LastName", e.target.value);
                    }
                  }}
                  disabled={fieldVal.DisplayContact === false}
                />
              </Col>
              <Col lg={6}>
                <FormControl
                  controlId="company"
                  label="Company Name"
                  type="text"
                  value={fieldVal.CompanyName}
                  onChange={(e) => _setFieldVal("CompanyName", e.target.value)}
                />
              </Col>
              <Col lg={6}>
                <FormControl
                  controlId="displayName"
                  label="Should we display:"
                  type="radios"
                  name="displayName"
                  onChange={(e) => {
                    const displayContact =
                      e.target.parentElement.innerText ===
                      "Business & Contact name";
                    _setFieldVal("DisplayContact", displayContact);
                  }}
                  options={[
                    {
                      label: "Business & Contact name",
                      checked: fieldVal.DisplayContact === true,
                    },
                    {
                      label: "Business name only",
                      checked: fieldVal.DisplayContact === false,
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardCardWithSave>
      <DashboardCardWithSave
        isSaving={isSaving}
        handleSave={_handleSave}
        hideSave={_.isEqual(
          {
            CategoryId: fieldVal.CategoryId,
            WorkingSinceMonth: fieldVal.WorkingSinceMonth,
            WorkingSinceYear: fieldVal.WorkingSinceYear,
            Intro: fieldVal.Intro,
          },
          {
            CategoryId: professionalSlice.CategoryId,
            WorkingSinceMonth: professionalSlice.WorkingSinceMonth,
            WorkingSinceYear: professionalSlice.WorkingSinceYear,
            Intro: professionalSlice.Intro,
          }
        )}
        saveDisabled={!isCategoryCardEnabled}
      >
        <Row className={`mt-md-4 mt-3 ${styles['both-box']}`}>
          <Col
            md="auto"
            xs={12}
            className={classNames(
              styles.content,
              "flex-md-grow-1 flex-md-shrink-1"
            )}
          >
            <Row>
              <Col lg={6}>
                <FormControl
                  controlId="professionalType"
                  label="Business Category"
                  type="select"
                  value={fieldVal.CompanyName}
                  onChange={(e) => {
                    _setFieldVal(
                      "CategoryId",
                      parseInt(e.target.selectedOptions[0].value)
                    );
                  }}
                  placeholder="Select Professional Type"
                  options={categories.map((category) => {
                    return {
                      selected: fieldVal.CategoryId == category.CategoryId,
                      value: category.CategoryId,
                      label: category.CategoryName,
                    };
                  })}
                />
              </Col>
              <Col xl="12">
                <FormControl
                  controlId="about"
                  label="Have been doing this work since:"
                  type="info"
                />
              </Col>
              <Col xl="12">
                <Row>
                  <Col xs={7}>
                    <FormControl
                      controlId="workingSinceMonth"
                      label="Month"
                      type="select"
                      value={fieldVal.WorkingSinceMonth}
                      onChange={(e) => {
                        _setFieldVal("WorkingSinceMonth", e.target.value);
                      }}
                      placeholder="Select Month"
                      options={[
                        {
                          value: "01",
                          label: "January",
                        },
                        {
                          value: "02",
                          label: "February",
                        },
                        {
                          value: "03",
                          label: "March",
                        },
                        {
                          value: "04",
                          label: "April",
                        },
                        {
                          value: "05",
                          label: "May",
                        },
                        {
                          value: "06",
                          label: "June",
                        },
                        {
                          value: "07",
                          label: "July",
                        },
                        {
                          value: "08",
                          label: "August",
                        },
                        {
                          value: "09",
                          label: "September",
                        },
                        {
                          value: "10",
                          label: "October",
                        },
                        {
                          value: "11",
                          label: "November",
                        },
                        {
                          value: "12",
                          label: "December",
                        },
                      ].map((month) => {
                        return {
                          selected: fieldVal.WorkingSinceMonth == month.value,
                          value: month.value,
                          label: month.label,
                        };
                      })}
                    />
                  </Col>
                  <Col xs={5}>
                    <FormControl
                      controlId="workingSinceYear"
                      label="Year"
                      type="text"
                      value={fieldVal.WorkingSinceYear}
                      onChange={(e) => {
                        const val = e.target.value;

                        if (
                          yearCheck(val) &&
                          (val.length < 4 || +val >= 1950)
                        ) {
                          _setFieldVal("WorkingSinceYear", val);
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              {/*<FormControl
                controlId="years"
                label="Have been doing this work since"
                type="datetime"
                onChange={(moment) =>{
                  if(moment && moment.format){
                    _setFieldVal("WorkingSince", moment.format("YYYY-MM-DDT00:00:00"))
                  }
                }}
                value={moment(fieldVal.WorkingSince).format("YYYY-MM")}
              />*/}
              <Col xl="12">
                <FormControl
                  controlId="about"
                  label="Intro"
                  type="textarea"
                  value={(fieldVal.Intro || "").slice(0, 2400)}
                  onChange={(e) => _setFieldVal("Intro", e.target.value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardCardWithSave>
      <DashboardCardWithSave
        isSaving={isSaving}
        handleSave={_handleSave}
        hideSave={_.isEqual(
          {
            Email: fieldVal.Email,
            Phone: fieldVal.Phone,
            Website: fieldVal.Website,
            NoPhysicalAddress: fieldVal.NoPhysicalAddress,
            Address: fieldVal.Address
          },
          {
            Email: professionalSlice.Email,
            Phone: professionalSlice.Phone,
            Website: professionalSlice.Website,
            NoPhysicalAddress: professionalSlice.NoPhysicalAddress,
            Address: professionalSlice.Address
          }
        )}
        saveDisabled={!isContactCardEnabled}
      >
        <Row className={`mt-md-4 mt-3 ${styles['both-box']}`}>
          <Col
            md="auto"
            xs={12}
            className={classNames(
              styles.content,
              "flex-md-grow-1 flex-md-shrink-1"
            )}
          >
            <Row>
              <Col lg={6}>
                <FormControl
                  controlId="email"
                  label="Email"
                  disabled
                  type="email"
                  value={fieldVal.Email}
                  onChange={(e) => _setFieldVal("Email", e.target.value)}
                />
              </Col>
              <Col lg={6}>
                <FormControl
                  controlId="phone"
                  label="Phone"
                  type="tel"
                  value={fieldVal.Phone}
                  onChange={(e) => {
                    const value = formatPhoneNumber(e.target.value);
                    if (value || value == "") {
                      _setFieldVal("Phone", value);
                    }
                  }}
                />
              </Col>
              <Col lg={6}>
                <FormControl
                  controlId="website"
                  label="Website"
                  type="text"
                  value={fieldVal.Website}
                  onChange={(e) => _setFieldVal("Website", e.target.value)}
                />
              </Col>
              <Col lg={6} className="mt-lg-4 pt-lg-2">
                <FormControl
                  controlId="noPhysicalPresence"
                  type="checkboxes"
                  options={[
                    {
                      label: "No physical address",
                      checked: fieldVal.NoPhysicalAddress,
                    },
                  ]}
                  onChange={(e) => {
                    if (e.target.checked) {
                      _batchSetFieldVal({
                        Address: "",
                        NoPhysicalAddress: true,
                      });
                    } else {
                      _setFieldVal("NoPhysicalAddress", false);
                    }
                  }}
                />
              </Col>
              <Col xs="12">
                <FormControl
                  controlId="address"
                  label="Address"
                  type="address"
                  value={fieldVal.Address || ""}
                  onChange={(val) => _setFieldVal("Address", val)}
                  onSelect={(results) =>
                    _setFieldVal(
                      "Address",
                      stringifyAddress(getFormattedAddress(results[0]))
                    )
                  }
                  disabled={fieldVal.NoPhysicalAddress}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardCardWithSave>
      <DashboardCardWithSave
        isSaving={isSaving}
        handleSave={_handleSave}
        hideSave={_.isEqual({
          ReviewsLinks: fieldVal.ReviewsLinks
        },
        {
          ReviewsLinks: professionalSlice.ReviewsLinks
        })}
        saveDisabled={!isEnabled}
      >
        <Row className={`mt-md-4 mt-3 ${styles['both-box']}`}>
          <Col
            md="auto"
            xs={12}
            className={classNames(
              styles.content,
              "flex-md-grow-1 flex-md-shrink-1"
            )}
          >
            <Row>
              <Col xs="12">
                <FormControl
                  type="multitext"
                  label="Website links to any existing customer reviews, we will add your best reviews to Hyponia (press enter to add link)"
                  value={fieldVal.ReviewsLinks}
                  onChange={(allItems) => {
                    _setFieldVal("ReviewsLinks", allItems);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardCardWithSave>
      <DashboardCardWithSave
        isSaving={isSaving}
        handleSave={_handleSave}
        hideSave={_.isEqual(fieldVal.ServiceCities, professionalSlice.ServiceCities)}
        saveDisabled={!isCitiesCardEnabled}
      >
        <Row className={`${styles['both-box']} mt-3`}>
          <Col
            md="auto"
            xs={12}
            className={classNames(
              styles.content,
              "flex-md-grow-1 flex-md-shrink-1"
            )}
          >
            <Row className="justify-content-center">
              <Col xs="12">
              <FormControl
                  controlId=""
                  label="Areas served"
                  type="info"
                />
              </Col>
              <Col xs="auto" md="6">
                <FormControl
                  controlId="areasServed"
                  label=""
                  type="checkboxes"
                  className="mb-0"
                  options={[
                    "Manhattan",
                    "Brooklyn",
                    "Queens",
                    "Bronx",
                    "Staten Island",
                    "Outside of NYC",
                  ].map((option) => {
                    return {
                      label: option,
                      checked:
                        fieldVal.ServiceCities.findIndex(
                          (sc) => sc === option
                        ) !== -1,
                    };
                  })}
                  onChange={(e) => {
                    let selectedCheckboxes = fieldVal.ServiceCities || [];
                    const index = selectedCheckboxes.findIndex(
                      (c) => c === e.target.name
                    );
                    if (e.target.checked && index === -1) {
                      _setFieldVal("ServiceCities", [
                        ...selectedCheckboxes,
                        e.target.name,
                      ]);
                    } else if (!e.target.checked && index !== -1) {
                      selectedCheckboxes.splice(index, 1);
                      _setFieldVal("ServiceCities", selectedCheckboxes);
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardCardWithSave>
    </Row>
  );
};

export default ProfessionalDetail;
