import React, {Component} from "react";
import {connect} from "react-redux";
import {Actions} from "../actions";
import {Redirect} from "react-router-dom";

class AuthContainer extends Component {
  state = {
    Email: "",
    Password: ""
  };

  componentWillMount() {
    this.props.doRefreshToken();
  }

  _handleChange(field, value) {
    this.setState({[field]: value});
  }

  _handleSubmit() {
    this.props.doLogin(this.state);
  }

  componentDidUpdate() {
    if (!this.props.isLoggedIn && !this.props.inTraffic) {
      window.location.replace(process.env.REACT_APP_WEBSITE_URL + "/login?redirect=professional");
    }
  }

  render() {
    const {from} = this.props.location.state || {
      from: {
        pathname: "/profile"
      }
    };

    if (this.props.isLoggedIn) {
      return <Redirect to={from}/>;
    }

    return null
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.auth
  };
};

export default connect(mapStateToProps, Actions)(AuthContainer);
