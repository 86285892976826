import { call, put } from "redux-saga/effects";
import { Actions, ActionTypes } from "../actions";
import { saveProfilePhoto } from "./networks";

export default [[ActionTypes.DO_SAVE_PROFILE_IMAGE, doSaveProfileImage]];

function* doSaveProfileImage({ payload }) {
  yield put(Actions.doingSaveProfileImage());

  const response = yield call(saveProfilePhoto, payload);

  if (!response.successful) {
    yield put(Actions.errorSaveProfileImage({ ...response.data }));
  } else {
    yield put(Actions.doneSaveProfileImage({ ...response.data }));
  }
}
