import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./index.scss";

const Messages = () => {
  return (
    <div>
      <div id={"professional-messages"} className={"dashboard--gutters"}>
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={8}>
              <h2 className={"remove--gutters mt-4"}> Messages </h2>
              <div className={"details-container"}>
                <div className={"content"}><h4>You don't have any Messages yet</h4></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      );
    </div>
  );
};

export default Messages;
