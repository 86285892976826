import { ActionTypes } from '../actions';
import queryParams from 'queryparams';

const defaultState = {
    inTraffic: false,
    accessToken: localStorage.getItem('access_token') || "",
    refreshToken: localStorage.getItem('refresh_token') || "",
    isLoggedIn: false,
    isGuest: false
};

export default (state = defaultState, action) => {
    state.isGuest = queryParams()['guest'] || false;

    try {
        switch(action.type) {
            case ActionTypes.DOING_LOGIN:
            case ActionTypes.DOING_REFRESH_TOKEN:
                return {
                    ...state,
                    inTraffic: true
                };

            case ActionTypes.DONE_LOGIN:
            case ActionTypes.DONE_REFRESH_TOKEN:
                return {
                    ...state,
                    inTraffic: false,
                    isLoggedIn: true
                };

            case ActionTypes.ERROR_REFRESH_TOKEN:
            case ActionTypes.ERROR_LOGIN:
                return {
                    ...state,
                    inTraffic: false,
                    isLoggedIn: false
                };

            case ActionTypes.DONE_LOGOUT:
                return {
                    ...state,
                    isLoggedIn: false
                };

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in auth reducer: ${ error.message || error.code || error }`, error);
    }
};
