import {call, put, select, delay} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';

export default [
    [ActionTypes.DO_SHOW_ERROR_MESSAGE, doShowErrorMessage],
    [ActionTypes.DO_SHOW_MESSAGE, doShowErrorMessage],
];

const getToastsState = (state) => state.toasts;

function * doShowErrorMessage ({ payload }) {
    const toasts = yield select(getToastsState);
    const messageId = toasts.messages.length + 1;

    yield put(Actions.doingShowErrorMessage({
        id: messageId,
        message: payload.message,
    }));

    yield delay(3000);

    yield put(Actions.doneShowErrorMessage(messageId));
}

function * doShowMessage ({ payload }) {
    const toasts = yield select(getToastsState);
    const messageId = toasts.messages.length + 1;

    yield put(Actions.doingShowErrorMessage({
        id: messageId,
        message: payload.message
    }));

    return messageId;
}