import React from 'react';
import "./index.scss";

const Loader = () => {
    return (
        <div className={"loader-container"}>
            <img src="/images/loading.gif" alt="Loading..."/>
        </div>
    );
};

export default Loader;