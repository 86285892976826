import React from "react";
import NavLink from "./NavLink";

const DashboardOptions = ({ currentPath }) => {
  return (
    <div className={"dashboard-options"}>
      {/*<NavLink icon={'professionals-dashboard'} label={'Dashboard'} path={"/dash"} currentPath={currentPath}/>*/}
      <NavLink
        icon={"profile"}
        label={"Public profile"}
        path={"/profile"}
        currentPath={currentPath}
      />
      <NavLink
        icon={"clients"}
        label={"Clients"}
        path={"/clients"}
        currentPath={currentPath}
      />
      <NavLink
        icon={"reviews"}
        label={"Reviews"}
        path={"/reviews"}
        currentPath={currentPath}
      />
      <NavLink
        icon={"settings"}
        label={"Account & settings"}
        path={"/settings"}
        currentPath={currentPath}
      />
    </div>
  );
};

export default DashboardOptions;
