import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { Actions } from "../actions";

class PrivateRoute extends Component {
    render() {
        const { children, auth, location, ...rest } = this.props;

        if (!auth.isLoggedIn && !auth.isGuest) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: location }
            }} />
        }

        return <Route
            {...rest}
        />;
    }
}

export default connect(state => {
    return {
        auth: state.auth
    }
}, Actions)(PrivateRoute);
