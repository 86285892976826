import { ActionTypes } from '../actions';
import _ from 'underscore';

const defaultState = {
    timelineData: [],
    isDefault: false,
    isTimelineLoading: true,
    stepInfo: {},
    isTimelineProfessionalsLoading: true,
    isTimelineProfessionalsLoaded: false,
    isTimelineStepLoading: true
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADING_TIMELINES:
                return {
                    ...state,
                    isTimelineLoading: true
                };
            case ActionTypes.LOADING_DEFAULT_TIMELINE:
                return {
                    ...state,
                    isTimelineLoading: true
                };
            case ActionTypes.LOADING_STEP_PROFESSIONALS:
                return {
                    ...state,
                    isTimelineProfessionalsLoading: true
                };

            case ActionTypes.LOADED_TIMELINES:
                return {
                    ...state,
                    timelines: action.payload,
                    selectedTimelineId: _.first(action.payload).Id,
                    isTimelineLoading: false
                };

            case ActionTypes.LOADED_DEFAULT_TIMELINE:
                return {
                    ...state,
                    timelines: action.payload,
                    selectedTimelineId: _.first(action.payload).Id,
                    isDefault: true,
                    isTimelineLoading: false,
                };

            case ActionTypes.LOADING_STEP_INFO:
                return {
                    ...state,
                    selectedStepId: action.payload.StepId || action.payload.DefaultStepId,
                    isTimelineStepLoading: true,
                    isTimelineProfessionalsLoading: true
                };

            case ActionTypes.LOADED_STEP_INFO:
                return {
                    ...state,
                    stepInfo: action.payload,
                    isTimelineStepLoading: false
                };

            case ActionTypes.ERROR_LOADING_STEP_INFO:
                return {
                    ...state,
                    isTimelineStepLoading: false
                };

            case ActionTypes.LOADED_STEP_PROFESSIONALS:
                return {
                    ...state,
                    stepProfessionals: action.payload,
                    isTimelineProfessionalsLoading: false,
                    isTimelineProfessionalsLoaded: true
                };

            case ActionTypes.ERROR_LOADING_STEP_PROFESSIONALS:
                return {
                    ...state,
                    stepProfessionals: {},
                    isTimelineProfessionalsLoading: false,
                    isTimelineProfessionalsLoaded: true
                };

            case ActionTypes.DOING_SAVE_TIMELINE:
                return {
                    ...state,
                    userInfo: action.payload
                };

            case ActionTypes.DONE_SAVE_TIMELINE:
                return {
                    ...state,
                    userInfo: action.payload
                };

            case ActionTypes.DOING_SAVE_NOTE:
                return {
                    ...state,
                    isSavingNote: true
                };

            case ActionTypes.DONE_SAVE_NOTE:
                return {
                    ...state,
                    isSavingNote: false,
                    stepInfo: {
                        ...state.stepInfo,
                        Notes: action.payload.Notes
                    }
                };

            case ActionTypes.DOING_HIRE_PROFESSIONAL:
                return {
                    ...state,
                    hiringProfessionalId: action.payload
                };

            case ActionTypes.DONE_HIRE_PROFESSIONAL:
                return {
                    ...state,
                    hiringProfessionalId: null,
                    stepProfessionals: [
                        ...state.stepProfessionals.map(category => {
                            if (category.CategoryId !== action.payload.CategoryId) {
                                return category;
                            }

                            return {
                                ...category,
                                HiredOn: action.payload.HiredOn,
                                HiredProfessionalExternalId: action.payload.HiredProfessionalExternalId,
                                HiredProfessionalId: action.payload.HiredProfessionalId,
                                ProfessionalExternal: action.payload.ProfessionalExternal,
                            }
                        })
                    ]
                };

            case ActionTypes.DOING_MARK_STEP_COMPLETED:
                return {
                    ...state,
                    isMarkingStepCompleted: true
                };

            case ActionTypes.DONE_MARK_STEP_COMPLETED:
                return {
                    ...state,
                    isMarkingStepCompleted: false,
                    stepInfo: {
                        ...state.stepInfo,
                        Completed: action.payload.Completed,
                        CompletedOn: action.payload.CompletedOn
                    },
                    timelines: [
                        ...state.timelines.map(timeline => {
                            if (timeline.Id !== state.selectedTimelineId) {
                                return timeline;
                            }

                            timeline.Steps = [
                                ...timeline.Steps.map(step => {
                                    if (step.Id !== state.selectedStepId) {
                                        return step;
                                    }

                                    return {
                                        ...step,
                                        Completed: action.payload.Completed,
                                        CompletedOn: action.payload.CompletedOn
                                    }
                                })
                            ];

                            return timeline;
                        })
                    ]
                };

            case ActionTypes.DOING_SAVE_DETAIL_FIELDS:
                return {
                    ...state,
                    isSavingDetailFields: true
                };

            case ActionTypes.DONE_SAVE_DETAIL_FIELDS:
                return {
                    ...state,
                    isSavingDetailFields: false,
                    stepInfo: {
                        ...state.stepInfo,
                        Details: action.payload.Details
                    }
                };

            case ActionTypes.DOING_SAVE_EXTERNAL_PROFESSIONAL:
                return {
                    ...state,
                    inTraffic: true
                };

            case ActionTypes.DONE_SAVE_EXTERNAL_PROFESSIONAL:
                return {
                    ...state,
                    stepProfessionals: state.stepProfessionals.map(prof => {
                        if (prof.CategoryId !== action.payload.CategoryId) return prof;
                        return action.payload;
                    })
                }

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in timeline reducer: ${ error.message || error.code || error }`, error);
    }
};
