import React, { Component } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Layout from "../../components/Layout"
import ProfileVisibility from "./ProfileVisibility";
import LeadSettings from "./LeadSettings";
import HomeBuyerCriteria from "./HomeBuyerCriteria";
import OnlineAccount from "./OnlineAccount";
import ContactInformation from "./ContactInformation";

class Settings extends Component {

  savePassword(password) {
    if (password !== "##**##**##**") {
      this.props.doSaveProfessionalDetails({
        ...this.props.professionals.professionalDetails,
        Password: password,
      });
    }
  }

  saveCard(token) {
    const email = this.props.professionals.professionalDetails.Email;
    this.props.doSaveStripeCustomer({
      Email: email,
      CardToken: token.id,
    });
  }


  render() {
    const {
      professionals: {
        professionalSettings,
        professionalDetails,
        isProfessionalSettingsSaving,
        isProfessionalDetailsSaving,
        isStripeCustomerSaving,
      },
    } = this.props;
    const promise = loadStripe(
      "pk_test_51H5c5kFm7u8c1AJ0QNbY3hbaQ4lQBAJjnUqFVtwOmoqygCLBFSOelsiKIDEhFWetTXaNrMdKsqXYr2oFgclyF68I00Ym8rfkbn"
    );

    return <Layout title={`Account & settings`}>
      <ProfileVisibility
        inTraffic={isProfessionalSettingsSaving}
        professionalSettings={professionalSettings}
        handleSave={this.props.doSaveProfessionalSettings}
      />
      <Elements stripe={promise}>
        <LeadSettings
          inTraffic={isProfessionalSettingsSaving || isStripeCustomerSaving}
          professionalSettings={professionalSettings}
          handleSave={this.props.doSaveProfessionalSettings}
          saveCard={this.saveCard.bind(this)}
        />
      </Elements>
      <HomeBuyerCriteria />
      <OnlineAccount
        professionalDetails={professionalDetails}
        savePassword={this.savePassword.bind(this)}
        inTraffic={isProfessionalDetailsSaving}
      />
      <ContactInformation
        professionalDetails={professionalDetails}
        professionalSettings={professionalSettings}
        handleSave={this.props.doSaveProfessionalSettings}
        inTraffic={isProfessionalSettingsSaving}
      />
    </Layout>
  }
}

export default Settings;
