import React from 'react';
import { Provider } from 'react-redux';
import Website from './containers/Website';
import './App.scss';

export default function App ({ routes, store }) {
  return (
      <Provider store = { store }>
        <Website />
      </Provider>
  );
}
