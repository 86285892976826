import React from 'react';
import { Link } from "react-router-dom";
import queryParams, { encode } from 'queryparams';

const CustomLink = ({ to, classes, children, params, target, onClick }) => {
    const qp = queryParams(params);
    return (
        <Link  onClick={onClick ? onClick : null}
        target={target ? "_blank" : null} to={to ? `${to}?${encode(qp)}`: null} className={classes}>
            {children}
        </Link>
    );
};

export default CustomLink;