import React from 'react';
import { Accordion, Card } from "react-bootstrap";
import classNames from 'classnames';
import _ from 'underscore';
import './index.scss';
import {getIconName} from "../../../utils";

const Documents = ({ documents }) => {
    const [opened, setOpened] = React.useState(false);
    if (_.isEmpty(documents)) { return null; }
    const allDocuments = _.chain(documents.Documents).values().flatten().filter(doc => !_.isEmpty(doc)).value();

    if (_.isEmpty(allDocuments)) { return null; }

    return (
        <Accordion className={"rui__timeline-documents"} defaultActiveKey="0">
            <Accordion.Toggle className={classNames({opened: opened})} as={Card.Header}  variant="link" eventKey="0" onClick={() => setOpened(!opened)}>
                <h6><img className={"icon icon-paperclip"}/>Documents</h6>
                <img className={"icon icon-caret"} alt=""/>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0" className={"mt-2"}>
                <React.Fragment>
                {
                    allDocuments.map(document => <a className={"new"} href={document.Link} target={"_blank"}><img className={`icon icon-${document.StepType ? getIconName(document.StepType) : "paperclip"}`}/>{ document.Name }</a>)
                }
                </React.Fragment>
            </Accordion.Collapse>
        </Accordion>
    );
};

export default Documents;