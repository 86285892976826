import React, {Component} from 'react';
import { connect } from "react-redux";
import { Actions } from "../actions";
import Reviews from "../pages/Reviews";

class ReviewsContainer extends Component {
    render() {
        return <Reviews {...this.props}/>;
    }
}

export default connect(state => state.professionals, Actions)(ReviewsContainer);
