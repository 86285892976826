import { ActionTypes } from '../actions';
import onboardingSteps from './onboarding-steps.json';
import { getFormattedAddress } from '../utils';
import queryParams from "queryparams";

const defaultState = {
    steps: onboardingSteps,
    currentStep: 1,
    values: {},
    isBack: false,
    userInfo: {},
    isLoading: false,
    inTraffic: false,
    guid: queryParams(window.location.search)['guid'] || localStorage.getItem("guid")
};

export default (state = defaultState, action) => {
    state.guid = queryParams()['guid'] || localStorage.getItem("guid");
    localStorage.setItem('guid', state.guid);
    try {
        switch(action.type) {
            case ActionTypes.LOADING_USER_INFO_BY_OBJECT_ID:
                return {
                    ...state,
                    userInfo: action.payload,
                    isLoading: true,
                    guid: queryParams(window.location.search)['guid'] || localStorage.getItem("guid")
                };

            case ActionTypes.LOADED_USER_INFO_BY_OBJECT_ID:
                return {
                    ...state,
                    userInfo: action.payload,
                    isLoading: false
                };

            case ActionTypes.SET_STEP_NUMBER:
                return {
                    ...state,
                    currentStep: action.payload
                };

            case ActionTypes.ONBOARDING_STEP_CHANGE:
                var value = null;
                if (action.payload.key === "LookingAddress") {
                    var formattedAddress = getFormattedAddress(action.payload.value);
                    value = formattedAddress ? {
                        City: formattedAddress.City,
                        State: formattedAddress.State,
                        formatted_address: action.payload.value.formatted_address
                    } : null;
                }

                if (action.payload.key === "CurrentAddress") {
                    var formattedAddress = getFormattedAddress(action.payload.value);
                    value = formattedAddress ? {
                        AddressLine2: "",
                        ...formattedAddress,
                        formatted_address: action.payload.value.formatted_address
                    } : null;
                }

                return {
                    ...state,
                    values: {
                        ...state.values,
                        [action.payload.stepId]: {
                            value: value || action.payload.value,
                            key: action.payload.key
                        }
                    }
                };

            case ActionTypes.HANDLE_ONBOARDING_BACK:
                return {
                    ...state,
                    currentStep: state.currentStep - 1,
                    isBack: true
                };

            case ActionTypes.DOING_SAVE_ONBOARDING_STEP:
                return {
                    ...state,
                    inTraffic: true,
                    guid: queryParams(window.location.search)['guid'] || localStorage.getItem("guid")
                };

            case ActionTypes.DONE_SAVE_ONBOARDING_STEP:
                return {
                    ...state,
                    currentStep: action.payload.QuestionId || state.currentStep + 1,
                    userInfo: action.payload,
                    inTraffic: false,
                    isBack: false
                };

            case ActionTypes.DOING_SAVE_TIMELINE:
                return {
                    ...state,
                    isLoading: true,
                    guid: queryParams(window.location.search)['guid'] || localStorage.getItem("guid")
                };

            case ActionTypes.DONE_SAVE_TIMELINE:
                return {
                    ...state,
                    isLoading: false
                };

            case ActionTypes.DOING_SAVE_USER_INFO:
                return {
                    ...state,
                    inTraffic: true,
                    guid: queryParams(window.location.search)['guid'] || localStorage.getItem("guid")
                };

            case ActionTypes.DONE_SAVE_USER_INFO:
                return {
                    ...state,
                    inTraffic: false
                };

            default:
                return state;
        }
    } catch(error) {
        console.error(`Error in userState reducer: ${ error.message || error.code || error }`, error);
    }
};
