import {call, put, select, delay} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';

export default [
    [ActionTypes.HANDLE_ERRORS, handleErrors],
];

function * handleErrors ({ payload }) {
    if (payload.code === 401) {
        // yield put(Actions.doShowErrorMessage({
        //     message: "You have been logged out.",
        // }));
        // yield put(Actions.doLogout());
    }
}