import React from "react";
import PropTypes from 'prop-types'
import {Col} from "react-bootstrap";
import classNames from "classnames"

import Card from "../Card"
import styles from './DashboardCard.module.scss'

export default function DashboardCard({
  children,
  className,
  title,
  beta,
  ...other
}) {

  return <Card className={className} {...other}>
    {title && <h5>{title}</h5>}
    {beta && <small className={classNames(styles.beta, "position-absolute red")}>NOT IN <span>BETA</span></small>}
    {children}
  </Card>
}

DashboardCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  beta: PropTypes.bool
}
