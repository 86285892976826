import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";

const NewSignup = () => {
  return (
    <div id="rui__dashboard-container" className="onboarding-complete">
      <div>
        <p>
          Thanks for signing up. Check your email for details on creating your
          account.
        </p>
      </div>
      <div>
      <p>
          If you don't find your email please make sure to check your spam/promotion/... folder
        </p>
      </div>
      <div className="centered">
        <Link
          onClick={() =>
            window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`)
          }
        >
          Home
        </Link>
      </div>
    </div>
  );
};

export default NewSignup;
